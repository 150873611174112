import { mapToDataOnly } from "../common/HttpUtility";
import { SelectionView } from "../common/Models";
import { CurrencyApiFactory, LocalCurrencyApiFactory } from "../../generated-api/financials";
import { ShopApiFactory } from "../../generated-api";

export class LocalCurrencyService {

    getBalance(): Promise<{
        average: number
        balance: number
        currencySymbol: string
        currencyTitle: string
        currencyId: string
        totalBalance: number
        walletId: string
    }[]> {
        return mapToDataOnly<any>(ShopApiFactory().buyerShopShopPropertiesGet());
    }

    get(): Promise<{
        currencyKey: string
        currencySymbol: string
        currencyTitle: string
        localCurrencyId: string
    }> {
        
        return mapToDataOnly<any>(LocalCurrencyApiFactory().apiV1LocalCurrencyGet());
    }

    getTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(CurrencyApiFactory().apiV1CurrencyTitleValueGet());
    }

    setLocalCurrency(shopId: string, currencyId: string): Promise<any> {
        return mapToDataOnly<any>(LocalCurrencyApiFactory().apiV1LocalCurrencyCurrencyIdPut(currencyId));
    }
}