/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
 */
export interface ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO>}
     * @memberof ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
 */
export interface ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'orderId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'imageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'imageSrc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'parentTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO
     */
    'createAt'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsContentArticlesArticleRowDTO
 */
export interface ApiGiftCommonDTOsContentArticlesArticleRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'details'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'published'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'imageName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'imageSrc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentArticlesArticleRowDTO
     */
    'categoryTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsContentSearchSearchSuggestionDTO
 */
export interface ApiGiftCommonDTOsContentSearchSearchSuggestionDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentSearchSearchSuggestionDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsContentSearchSearchSuggestionDTO
     */
    'value'?: string;
    /**
     * 
     * @type {ApiGiftCommonDTOsContentSearchSearchSuggestionDTOSuggestionType}
     * @memberof ApiGiftCommonDTOsContentSearchSearchSuggestionDTO
     */
    'type'?: ApiGiftCommonDTOsContentSearchSearchSuggestionDTOSuggestionType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonDTOsContentSearchSearchSuggestionDTOSuggestionType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ApiGiftCommonDTOsContentSearchSearchSuggestionDTOSuggestionType = typeof ApiGiftCommonDTOsContentSearchSearchSuggestionDTOSuggestionType[keyof typeof ApiGiftCommonDTOsContentSearchSearchSuggestionDTOSuggestionType];


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO
 */
export interface ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO
     */
    'totalSoldCount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO
     */
    'productSoldCount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO
     */
    'sellerShopId'?: string;
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerPlanDTO>}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO
     */
    'plans'?: Array<ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerPlanDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO
     */
    'delayDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO
     */
    'inventoryCount'?: number | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerPlanDTO
 */
export interface ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerPlanDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerPlanDTO
     */
    'countFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerPlanDTO
     */
    'countTo'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerPlanDTO
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerPlanDTO
     */
    'isApiPlan'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryConfigManualSalesInventoryConfigManualSalesPlanDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryConfigManualSalesInventoryConfigManualSalesPlanDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsInventoryInventoryConfigManualSalesInventoryConfigManualSalesPlanDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryConfigManualSalesInventoryConfigManualSalesPlanDTO
     */
    'countFrom'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryConfigManualSalesInventoryConfigManualSalesPlanDTO
     */
    'countTo'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryConfigManualSalesInventoryConfigManualSalesPlanDTO
     */
    'profitPercent'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryInventoryAddConditionsDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryInventoryAddConditionsDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryAddConditionsDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryAddConditionsDTO
     */
    'orderProductPendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryAddConditionsDTO
     */
    'manualOrderProductPendingCount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryInventoryCategoryDetailsDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryInventoryCategoryDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryCategoryDetailsDTO
     */
    'categoryTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigDeliveryDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigDeliveryDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigDeliveryDTO
     */
    'delayFromNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigDeliveryDTO
     */
    'delayToNumber'?: number;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryDeliveryDelayType}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigDeliveryDTO
     */
    'delayType'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryDeliveryDelayType;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigDeliveryDTO
     */
    'enableParticipateOffers'?: boolean;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigMinimumAlertDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigMinimumAlertDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigMinimumAlertDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigMinimumAlertDTO
     */
    'isAutoCalculatedCount'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigMinimumAlertDTO
     */
    'customCount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryInventoryDeficitDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryInventoryDeficitDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryDeficitDTO
     */
    'sourceTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryDeficitDTO
     */
    'sourceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryDeficitDTO
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryDeficitDTO
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryInventoryFinancialStatisticsDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryInventoryFinancialStatisticsDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryFinancialStatisticsDTO
     */
    'sourceTotalBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryFinancialStatisticsDTO
     */
    'totalDeficits'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryFinancialStatisticsDTO
     */
    'availableAmount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO
     */
    'inventoryShopId'?: string;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO
     */
    'status'?: ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO
     */
    'transactionAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO
     */
    'isToSource'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventorySellerInventoryProductCategoryDTO
 */
export interface ApiGiftCommonDTOsInventoryInventorySellerInventoryProductCategoryDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySellerInventoryProductCategoryDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySellerInventoryProductCategoryDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus}
     * @memberof ApiGiftCommonDTOsInventoryInventorySellerInventoryProductCategoryDTO
     */
    'status'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySellerInventoryProductCategoryDTO
     */
    'rejectedAt'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
     */
    'sourceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
     */
    'sourceTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
     */
    'date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
     */
    'totalPrice'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
     */
    'buyAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
     */
    'productTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
     */
    'sourceTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
     */
    'buyOrderNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
     */
    'deficitCount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO
     */
    'shopCollectionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO
     */
    'deficitCount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryShopInventoriesInventorySecretInformationDto
 */
export interface ApiGiftCommonDTOsInventoryInventoryShopInventoriesInventorySecretInformationDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopInventoriesInventorySecretInformationDto
     */
    'information'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryShopInventoriesUniqueSellerOrderNumberDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryShopInventoriesUniqueSellerOrderNumberDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopInventoriesUniqueSellerOrderNumberDTO
     */
    'buyPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopInventoriesUniqueSellerOrderNumberDTO
     */
    'orderDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryShopInventoriesUniqueSellerOrderNumberDTO
     */
    'sourceId'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO
 */
export interface ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO
     */
    'shopCurrencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO
     */
    'sourceCurrencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO
     */
    'inventorySourceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO
     */
    'sourceAmountValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO
     */
    'shopAmountValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO
     */
    'transactionAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO
     */
    'isToSource'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventorySourceTransactionsCurrencyInventorySourceTransactionDTO
 */
export interface ApiGiftCommonDTOsInventoryInventorySourceTransactionsCurrencyInventorySourceTransactionDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsCurrencyInventorySourceTransactionDTO
     */
    'currencyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsCurrencyInventorySourceTransactionDTO
     */
    'balance'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO
 */
export interface ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO
     */
    'inventorySourceTransactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO
     */
    'sourceAmountValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO
     */
    'shopAmountValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO
     */
    'transactionAt'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO
 */
export interface ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceDetailsDTO
 */
export interface ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceDetailsDTO
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO
 */
export interface ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO
     */
    'baseCurrencyBalance'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO
 */
export interface ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto
 */
export interface ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto
     */
    'inventoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'productTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'inventoryShopId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'inventoryShopTitle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'isCanceled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'isWebhookDelivered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'webhookDeliveredLastError'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'sourceDocument'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'sourceKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'sourceSubKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'sourceDeliveryKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'lastProccess'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'createDate'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO
 */
export interface ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO {
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus}
     * @memberof ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO
     */
    'status'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
 */
export interface ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
     */
    'withoutConflictValidation'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
     */
    'price'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
     */
    'buyDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
     */
    'sourceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
     */
    'deficitCount'?: number | null;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
     */
    'inventoryItems'?: Array<Array<string>> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO
     */
    'specifiedOrderItemIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
 */
export interface ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'information'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'safeItemId'?: string | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'status'?: ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'buyPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'buyerTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'buyerShopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'enterByOrderItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'outBySourceKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'outBySourceDocument'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'sellerSoldAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'sellerProfitAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'soldAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'createAt'?: string;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
 */
export interface ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'fromId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'toId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'handlerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'memberUserDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'modifyAt'?: string;
    /**
     * 
     * @type {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'status'?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'isGood'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'memberUserId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'isMediationRequested'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'mediationRequestDate'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDTO
 */
export interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDTO {
    /**
     * 
     * @type {ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanSystemDTO}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDTO
     */
    'systemDetails'?: ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanSystemDTO;
    /**
     * 
     * @type {ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanPricingConfigDTO}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDTO
     */
    'pricingConfig'?: ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanPricingConfigDTO;
    /**
     * 
     * @type {ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanInventoryConfigDTO}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDTO
     */
    'inventoryConfig'?: ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanInventoryConfigDTO;
    /**
     * 
     * @type {ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDeliveryConfigDTO}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDTO
     */
    'deliveryConfig'?: ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDeliveryConfigDTO;
    /**
     * 
     * @type {ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanManualPlanDTO}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDTO
     */
    'plansConfig'?: ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanManualPlanDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDTO
     */
    'isPro'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDeliveryConfigDTO
 */
export interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDeliveryConfigDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDeliveryConfigDTO
     */
    'enableParticipateOffers'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDeliveryConfigDTO
     */
    'delayFromNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDeliveryConfigDTO
     */
    'delayToNumber'?: number;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryDeliveryDelayType}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDeliveryConfigDTO
     */
    'delayType'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryDeliveryDelayType;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanInventoryConfigDTO
 */
export interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanInventoryConfigDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanInventoryConfigDTO
     */
    'averagePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanInventoryConfigDTO
     */
    'averagePriceWithCurrencyRate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanInventoryConfigDTO
     */
    'isActiveMinimumAlert'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanInventoryConfigDTO
     */
    'isActiveMinimumCalculated'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanInventoryConfigDTO
     */
    'minimumAlertCustomCount'?: number;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryAutoBasePriceMethod}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanInventoryConfigDTO
     */
    'inventoryAutoBasePriceMethod'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryAutoBasePriceMethod;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanManualPlanDTO
 */
export interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanManualPlanDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryInventoryConfigManualSalesInventoryConfigManualSalesPlanDTO>}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanManualPlanDTO
     */
    'pricingStatics'?: Array<ApiGiftCommonDTOsInventoryInventoryConfigManualSalesInventoryConfigManualSalesPlanDTO> | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanPricingConfigDTO
 */
export interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanPricingConfigDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanPricingConfigDTO
     */
    'baseCustomPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanPricingConfigDTO
     */
    'lastAveragePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanPricingConfigDTO
     */
    'lastAutoBasePriceAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanPricingConfigDTO
     */
    'minimumBasePrice'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanPricingConfigDTO
     */
    'isAutoBasePrice'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanSystemDTO
 */
export interface ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanSystemDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanSystemDTO
     */
    'shopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanSystemDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanSystemDTO
     */
    'productTitle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanSystemDTO
     */
    'isMainCurrency'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanSystemDTO
     */
    'sharePercent'?: number;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanSystemDTO
     */
    'status'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO
 */
export interface ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigDeliveryDTO}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO
     */
    'delivery'?: ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigDeliveryDTO;
    /**
     * 
     * @type {ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigMinimumAlertDTO}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO
     */
    'minimumAlert'?: ApiGiftCommonDTOsInventoryInventoryInventoryConfigInventoryConfigMinimumAlertDTO;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryAutoBasePriceMethod}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO
     */
    'inventoryAutoBasePriceMethod'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryAutoBasePriceMethod;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO
     */
    'minimumBasePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO
     */
    'baseCustomPrice'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO
     */
    'isAutoBasePrice'?: boolean;
    /**
     * 
     * @type {ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanDTO}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO
     */
    'plansConfig'?: ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO
     */
    'isPro'?: boolean;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanDTO
 */
export interface ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryInventoryConfigManualSalesInventoryConfigManualSalesPlanDTO>}
     * @memberof ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanDTO
     */
    'plans'?: Array<ApiGiftCommonDTOsInventoryInventoryConfigManualSalesInventoryConfigManualSalesPlanDTO> | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
 */
export interface ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'reviewRequestSupportTicketId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'orderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'informations'?: Array<string> | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'status'?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'member'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'memberId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'sellerShopTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'sellerId'?: string;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderType}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'orderType'?: ApiGiftCommonEnumsOrdersOrdersOrderType;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO
     */
    'price'?: number | null;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO
 */
export interface ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO
     */
    'isMarked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO
     */
    'orderId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO
     */
    'orderProductIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
 */
export interface ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'returnReasonCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'productTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'sellPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'modifyAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'handlerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'handlerUserId'?: string | null;
    /**
     * 
     * @type {ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'department'?: ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid;
    /**
     * 
     * @type {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'status'?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'isGood'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'canRequestMediation'?: boolean;
    /**
     * 
     * @type {Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO>}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'comments'?: Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO> | null;
    /**
     * 
     * @type {Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO>}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'replies'?: Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'orderProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     * @deprecated
     */
    'inventoryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'buyerId'?: string;
    /**
     * 
     * @type {ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'inventory'?: ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'orderItemStatus'?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'sellerRequestedMoreAmount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'hasBuyerPaidMoreAmount'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'mediationRequestDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'isMediationDone'?: boolean | null;
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid>}
     * @memberof ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto
     */
    'tags'?: Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid> | null;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto
 */
export interface ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto
     */
    'deliveryKey'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersBuyerNotSoldOrderCountDTO
 */
export interface ApiGiftCommonDTOsOrdersOrdersBuyerNotSoldOrderCountDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerNotSoldOrderCountDTO
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
 */
export interface ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'inventoryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'informations'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'safeItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'deliveredAt'?: string | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'status'?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'isSold'?: boolean;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderType}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'orderType'?: ApiGiftCommonEnumsOrdersOrdersOrderType;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'inventoryCollectionTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'reviewRequestSupportTicketId'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
 */
export interface ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderStatus}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'status'?: ApiGiftCommonEnumsOrdersOrdersOrderStatus;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'sellers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'productCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'deliveredCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'memberDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'memberUserId'?: string | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderType}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'orderType'?: ApiGiftCommonEnumsOrdersOrdersOrderType;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'addToInventory'?: boolean;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto
 */
export interface ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto
     */
    'addToInventory'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersOrderRelativeStatisticsDto
 */
export interface ApiGiftCommonDTOsOrdersOrdersOrderRelativeStatisticsDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderRelativeStatisticsDto
     */
    'totalRelativeOrderItemCount'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderRelativeStatisticsDto
     */
    'totalReviewRequestPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderRelativeStatisticsDto
     */
    'orderItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderRelativeStatisticsDto
     */
    'reviewRequestPercent'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersOrderStatisticsDto
 */
export interface ApiGiftCommonDTOsOrdersOrdersOrderStatisticsDto {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderStatisticsDto
     */
    'saleOrderItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderStatisticsDto
     */
    'saleReviewRequestPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderStatisticsDto
     */
    'buyOrderItemCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderStatisticsDto
     */
    'buyReviewRequestPercent'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO
 */
export interface ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO
     */
    'hasStar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO
     */
    'hasPendingOnly'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
 */
export interface ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderStatus}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
     */
    'status'?: ApiGiftCommonEnumsOrdersOrdersOrderStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
     */
    'orderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
     */
    'buyerTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
     */
    'buyerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
     */
    'totalCashoutAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
     */
    'totalRefund'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
     */
    'totalShare'?: number;
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO>}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO
     */
    'products'?: Array<ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO> | null;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
 */
export interface ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'shareAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'cashoutAmount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'isComplete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'deliveredCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'refundCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'pendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsProductDTO
     */
    'unitPrice'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO
 */
export interface ApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO
     */
    'safeItemId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO
     */
    'informations'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO
     */
    'deliveryAt'?: string | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO
     */
    'status'?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
 */
export interface ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'totalShareAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'totalCashoutAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'buyerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'buyerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderStatus}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'status'?: ApiGiftCommonEnumsOrdersOrdersOrderStatus;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersSellersOrderCountDTO
 */
export interface ApiGiftCommonDTOsOrdersOrdersSellersOrderCountDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellersOrderCountDTO
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersSellersOrderStatisticsDTO
 */
export interface ApiGiftCommonDTOsOrdersOrdersSellersOrderStatisticsDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersSellersOrderStatisticsDTO
     */
    'dailyAverageCount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
 */
export interface ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'productId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'customLimitPolicy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'allowAllSellers'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'allowedSellerShopIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'denySellerShopIds'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'minimumCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'maximumCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'outDailyAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'plannedDayCount'?: number;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersBuyPlanStatus}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto
     */
    'status'?: ApiGiftCommonEnumsOrdersOrdersBuyPlanStatus;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto
 */
export interface ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto
     */
    'allowAllSellers'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto
     */
    'allowedSellerShopIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto
     */
    'denySellerShopIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO
 */
export interface ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO
     */
    'sellerShopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO
     */
    'unitPrice'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO
 */
export interface ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO
     */
    'count'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO
     */
    'productTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO
     */
    'productId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO
     */
    'productInventoryAvailableCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO
     */
    'sellerShopId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO
     */
    'imageId'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO
 */
export interface ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO
     */
    'productBasketId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
 */
export interface ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'apiKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'secretKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'orderProductChangeStatusWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'productChangeStatusWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'webHookSecurityHeaderKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'webHookSecurityHeaderValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'walletDepositWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'takeoutInventoryWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'supportTicketUpdatedWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'sellReviewRequestCreatedWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO
     */
    'takeoutInventoryRefundedWebHookPath'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO
 */
export interface ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO
     */
    'orderProductChangeStatusWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO
     */
    'productChangeStatusWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO
     */
    'webHookSecurityHeaderValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO
     */
    'walletDepositWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO
     */
    'takeoutInventoryWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO
     */
    'sellReviewRequestCreatedWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO
     */
    'supportTicketUpdatedWebHookPath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO
     */
    'takeoutInventoryRefundedWebHookPath'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsConfigSetShopApiConfigResponseDTO
 */
export interface ApiGiftCommonDTOsShopsConfigSetShopApiConfigResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigSetShopApiConfigResponseDTO
     */
    'secretKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsConfigSetShopApiConfigResponseDTO
     */
    'accessKey'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO
 */
export interface ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
 */
export interface ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'packageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'shopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'handledAt'?: string | null;
    /**
     * 
     * @type {WorkspacesCommonEnumsPoliciesPackageStatus}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'status'?: WorkspacesCommonEnumsPoliciesPackageStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'handlerDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'handlerUserId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'isDisable'?: boolean;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsShopsGetShopPolicyDTO
 */
export interface ApiGiftCommonDTOsShopsShopsGetShopPolicyDTO {
    /**
     * 
     * @type {WorkspacesCommonEnumsPoliciesPackageStatus}
     * @memberof ApiGiftCommonDTOsShopsShopsGetShopPolicyDTO
     */
    'status'?: WorkspacesCommonEnumsPoliciesPackageStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsGetShopPolicyDTO
     */
    'createDate'?: string | null;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsShopsLocalShopCurrencyDTO
 */
export interface ApiGiftCommonDTOsShopsShopsLocalShopCurrencyDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsLocalShopCurrencyDTO
     */
    'localCurrencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsLocalShopCurrencyDTO
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsLocalShopCurrencyDTO
     */
    'currencyTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsLocalShopCurrencyDTO
     */
    'currencyKey'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonDTOsShopsShopsShopPropertiesDTO
 */
export interface ApiGiftCommonDTOsShopsShopsShopPropertiesDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsShopPropertiesDTO
     */
    'walletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsShopPropertiesDTO
     */
    'currencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsShopPropertiesDTO
     */
    'currencyTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonDTOsShopsShopsShopPropertiesDTO
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsShopsShopPropertiesDTO
     */
    'balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsShopsShopPropertiesDTO
     */
    'totalBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonDTOsShopsShopsShopPropertiesDTO
     */
    'average'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonEnumsInventoryInventoryShopsInventoryAutoBasePriceMethod = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ApiGiftCommonEnumsInventoryInventoryShopsInventoryAutoBasePriceMethod = typeof ApiGiftCommonEnumsInventoryInventoryShopsInventoryAutoBasePriceMethod[keyof typeof ApiGiftCommonEnumsInventoryInventoryShopsInventoryAutoBasePriceMethod];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonEnumsInventoryInventoryShopsInventoryDeliveryDelayType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ApiGiftCommonEnumsInventoryInventoryShopsInventoryDeliveryDelayType = typeof ApiGiftCommonEnumsInventoryInventoryShopsInventoryDeliveryDelayType[keyof typeof ApiGiftCommonEnumsInventoryInventoryShopsInventoryDeliveryDelayType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus = typeof ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus[keyof typeof ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus = typeof ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus[keyof typeof ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonEnumsOrdersOrdersBuyPlanStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ApiGiftCommonEnumsOrdersOrdersBuyPlanStatus = typeof ApiGiftCommonEnumsOrdersOrdersBuyPlanStatus[keyof typeof ApiGiftCommonEnumsOrdersOrdersBuyPlanStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonEnumsOrdersOrdersOrderProductStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8
} as const;

export type ApiGiftCommonEnumsOrdersOrdersOrderProductStatus = typeof ApiGiftCommonEnumsOrdersOrdersOrderProductStatus[keyof typeof ApiGiftCommonEnumsOrdersOrdersOrderProductStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonEnumsOrdersOrdersOrderStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type ApiGiftCommonEnumsOrdersOrdersOrderStatus = typeof ApiGiftCommonEnumsOrdersOrdersOrderStatus[keyof typeof ApiGiftCommonEnumsOrdersOrdersOrderStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftCommonEnumsOrdersOrdersOrderType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ApiGiftCommonEnumsOrdersOrdersOrderType = typeof ApiGiftCommonEnumsOrdersOrdersOrderType[keyof typeof ApiGiftCommonEnumsOrdersOrdersOrderType];


/**
 * 
 * @export
 * @interface ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
 */
export interface ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'realPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'createDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'currencyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'minimumPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'yourShopPrice'?: number | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'status'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus;
    /**
     * 
     * @type {ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'suggestion'?: ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'availableInventory'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'minimumInventory'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'dailySoldCountAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'requiredCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'inPendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'buyPendingCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'isPinned'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'buyWhenNeededOnly'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails
     */
    'buySuggestionCount'?: number;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonQueriesHybridGetPagedProductsQueryResponse
 */
export interface ApiGiftCommonQueriesHybridGetPagedProductsQueryResponse {
    /**
     * 
     * @type {Array<ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails>}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryResponse
     */
    'products'?: Array<ApiGiftCommonQueriesHybridGetPagedProductsQueryProductDetails> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQueryResponse
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion
 */
export interface ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion
     */
    'availableInventory'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion
     */
    'minimumInventory'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion
     */
    'dailySoldCountAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion
     */
    'requiredCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion
     */
    'inPendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion
     */
    'buyPendingCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion
     */
    'isPinned'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion
     */
    'buyWhenNeededOnly'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetPagedProductsQuerySuggestion
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse
 */
export interface ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse
     */
    'realPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse
     */
    'currencyId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse
     */
    'numberOfSell'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO>}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse
     */
    'sellers'?: Array<ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerDTO> | null;
    /**
     * 
     * @type {ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponseOwnedSellPlanModel}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse
     */
    'ownedSellPlan'?: ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponseOwnedSellPlanModel;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponseOwnedSellPlanModel
 */
export interface ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponseOwnedSellPlanModel {
    /**
     * 
     * @type {ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponseOwnedSellPlanModel
     */
    'status'?: ApiGiftCommonEnumsInventoryInventoryShopsInventoryShopBasePlanStatus;
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerPlanDTO>}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponseOwnedSellPlanModel
     */
    'plans'?: Array<ApiGiftCommonDTOsInventoryBuyBuyDetailsProductSellerPlanDTO> | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponseOwnedSellPlanModel
     */
    'delayDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponseOwnedSellPlanModel
     */
    'availableInventoryCount'?: number;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonQueriesInventoryInventoryGetInventoryAvailableCountDetailsQueryResponse
 */
export interface ApiGiftCommonQueriesInventoryInventoryGetInventoryAvailableCountDetailsQueryResponse {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesInventoryInventoryGetInventoryAvailableCountDetailsQueryResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesInventoryInventoryGetInventoryAvailableCountDetailsQueryResponse
     */
    'hasOrderIdCount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
 */
export interface ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
     */
    'reviewRequestId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
     */
    'productTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
     */
    'orderDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
     */
    'informations'?: Array<string> | null;
    /**
     * 
     * @type {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus}
     * @memberof ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
     */
    'status'?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
     */
    'sellerPrice'?: number;
}


/**
 * 
 * @export
 * @interface ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString
 */
export interface ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString
     */
    'isOut'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO
 */
export interface ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {Array<ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO>}
     * @memberof ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO
     */
    'childs'?: Array<ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO> | null;
}
/**
 * 
 * @export
 * @interface ApiGiftCommonQueryStringsShopsProductProductListDTO
 */
export interface ApiGiftCommonQueryStringsShopsProductProductListDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueryStringsShopsProductProductListDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftCommonQueryStringsShopsProductProductListDTO
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftDomainInterfacesNotificationResponse
 */
export interface ApiGiftDomainInterfacesNotificationResponse {
    /**
     * 
     * @type {Array<ApiGiftDomainInterfacesNotificationResponseNotificationView>}
     * @memberof ApiGiftDomainInterfacesNotificationResponse
     */
    'notifications'?: Array<ApiGiftDomainInterfacesNotificationResponseNotificationView> | null;
}
/**
 * 
 * @export
 * @interface ApiGiftDomainInterfacesNotificationResponseNotificationView
 */
export interface ApiGiftDomainInterfacesNotificationResponseNotificationView {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftDomainInterfacesNotificationResponseNotificationView
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftDomainInterfacesNotificationResponseNotificationView
     */
    'createDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftDomainInterfacesNotificationResponseNotificationView
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftDomainInterfacesNotificationResponseNotificationView
     */
    'message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftDomainInterfacesNotificationResponseNotificationView
     */
    'seen'?: boolean | null;
    /**
     * 
     * @type {ApiGiftGatewayCommonEnumsBaseMessageChannel}
     * @memberof ApiGiftDomainInterfacesNotificationResponseNotificationView
     */
    'channel'?: ApiGiftGatewayCommonEnumsBaseMessageChannel;
}


/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsOrdersOfferRowDto
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsOrdersOfferRowDto {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsOrdersOfferRowDto>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'rows'?: Array<ApiGiftGatewayCommonDTOsOrdersOfferRowDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberInvitationRowDto
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberInvitationRowDto {
    /**
     * 
     * @type {Array<WorkspacesCommonDtosShopMembersShopMemberInvitationRowDto>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberInvitationRowDto
     */
    'rows'?: Array<WorkspacesCommonDtosShopMembersShopMemberInvitationRowDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberInvitationRowDto
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberRowDTO {
    /**
     * 
     * @type {Array<WorkspacesCommonDtosShopMembersShopMemberRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberRowDTO
     */
    'rows'?: Array<WorkspacesCommonDtosShopMembersShopMemberRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO {
    /**
     * 
     * @type {Array<WorkspacesCommonDtosShopsShopRowDTO>}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO
     */
    'rows'?: Array<WorkspacesCommonDtosShopsShopRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTitleValueDTO
 */
export interface ApiGiftGatewayCommonDTOsBaseTitleValueDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTO
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemDouble
 */
export interface ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemDouble {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemDouble
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemDouble
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid
 */
export interface ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemString
 */
export interface ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemString {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemString
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemString
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO
     */
    'financialAccountId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO
     */
    'inventorySourceId'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO
     */
    'isAccepted'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO
     */
    'amount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto {
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
     */
    'sourceWalletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
     */
    'destinationWalletId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto
     */
    'addDestinationToFavorites'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto
 */
export interface ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto
     */
    'transactionId'?: string;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto
 */
export interface ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto
     */
    'duplicateInformationAccepted'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDto
 */
export interface ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDto {
    /**
     * 
     * @type {Array<ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDtoProductDto>}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDto
     */
    'products'?: Array<ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDtoProductDto> | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDtoProductDto
 */
export interface ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDtoProductDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDtoProductDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDtoProductDto
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDtoProductDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDtoProductDto
     */
    'realPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDtoProductDto
     */
    'currencyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDtoProductDto
     */
    'imageSrc'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO
 */
export interface ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO
 */
export interface ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO
     */
    'permissionId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto
 */
export interface ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto {
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto
     */
    'addToInventory'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto
     */
    'expireAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto
     */
    'isFixed'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsOrdersOfferRowDto
 */
export interface ApiGiftGatewayCommonDTOsOrdersOfferRowDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'productTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'availableInventory'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'orderingPlanStatus'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'inCommingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'sellerBlockCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'buyerBlockCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsOrdersOfferRowDto
     */
    'outOfPriceRangeCount'?: number;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsPricingProductPriceCompareDto
 */
export interface ApiGiftGatewayCommonDTOsPricingProductPriceCompareDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsPricingProductPriceCompareDto
     */
    'productId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsPricingProductPriceCompareDto
     */
    'yourPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsPricingProductPriceCompareDto
     */
    'minimumOtherPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGiftGatewayCommonDTOsPricingProductPriceCompareDto
     */
    'minimumOtherOfferPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
 */
export interface ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'hasTwoFactor'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'privileges'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftGatewayCommonEnumsBaseMessageChannel = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ApiGiftGatewayCommonEnumsBaseMessageChannel = typeof ApiGiftGatewayCommonEnumsBaseMessageChannel[keyof typeof ApiGiftGatewayCommonEnumsBaseMessageChannel];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus = typeof ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus[keyof typeof ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus];


/**
 * 
 * @export
 * @interface AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse
 */
export interface AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse
     */
    'recoveryCodes'?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuthenticationCommonEnumsUserStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type AuthenticationCommonEnumsUserStatus = typeof AuthenticationCommonEnumsUserStatus[keyof typeof AuthenticationCommonEnumsUserStatus];


/**
 * 
 * @export
 * @interface AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
 */
export interface AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {AuthenticationCommonEnumsUserStatus}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'status'?: AuthenticationCommonEnumsUserStatus;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'isTwoFactorEnabled'?: boolean;
}


/**
 * 
 * @export
 * @interface IdentityCommonDtosChangeRegisterEmailDTO
 */
export interface IdentityCommonDtosChangeRegisterEmailDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosChangeRegisterEmailDTO
     */
    'newEmail': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosChangeRegisterEmailDTO
     */
    'returnURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosChangeRegisterEmailDTO
     */
    'oldEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosConfirmEmailDTO
 */
export interface IdentityCommonDtosConfirmEmailDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosConfirmEmailDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosConfirmEmailDTO
     */
    'returnURL'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosForgotPasswordDTO
 */
export interface IdentityCommonDtosForgotPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosForgotPasswordDTO
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosRecoveryTwoFactorDTO
 */
export interface IdentityCommonDtosRecoveryTwoFactorDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRecoveryTwoFactorDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRecoveryTwoFactorDTO
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityCommonDtosRecoveryTwoFactorDTO
     */
    'disableTwoFactor'?: boolean;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosRegisterUserDTO
 */
export interface IdentityCommonDtosRegisterUserDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'lastname': string;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosResetPasswordDTO
 */
export interface IdentityCommonDtosResetPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosResetPasswordDTO
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosResetPasswordDTO
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosSignInDTO
 */
export interface IdentityCommonDtosSignInDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosSignInDTO
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosSignInDTO
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosSignInDTO
     */
    'totp'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosUpdateMyUserInformationDTO
 */
export interface IdentityCommonDtosUpdateMyUserInformationDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosUpdateMyUserInformationDTO
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosUpdateMyUserInformationDTO
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosVerifyForgotPasswordDTO
 */
export interface IdentityCommonDtosVerifyForgotPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosVerifyForgotPasswordDTO
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosVerifyForgotPasswordDTO
     */
    'newPassowrd': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosVerifyForgotPasswordDTO
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface MicrosoftAspNetCoreMvcProblemDetails
 */
export interface MicrosoftAspNetCoreMvcProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof MicrosoftAspNetCoreMvcProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftAspNetCoreMvcProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MicrosoftAspNetCoreMvcProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftAspNetCoreMvcProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftAspNetCoreMvcProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
 */
export interface WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO>}
     * @memberof WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
 */
export interface WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO {
    /**
     * 
     * @type {Array<ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO>}
     * @memberof WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'rows'?: Array<ApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
 */
export interface WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO {
    /**
     * 
     * @type {Array<WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO>}
     * @memberof WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'rows'?: Array<WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTitleValueDTO
 */
export interface WorkspacesCommonDtosBaseTitleValueDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosBaseTitleValueDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosBaseTitleValueDTO
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosBaseTitleValueDTOSystemGuid
 */
export interface WorkspacesCommonDtosBaseTitleValueDTOSystemGuid {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosBaseTitleValueDTOSystemGuid
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosBaseTitleValueDTOSystemGuid
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosMembersEditUserPrivilegeDTO
 */
export interface WorkspacesCommonDtosMembersEditUserPrivilegeDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosMembersEditUserPrivilegeDTO
     */
    'privilegeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosMembersEditUserPrivilegeDTO
     */
    'isSelected'?: boolean;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosMembersUserPrivilegesRowDTO
 */
export interface WorkspacesCommonDtosMembersUserPrivilegesRowDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosMembersUserPrivilegesRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosMembersUserPrivilegesRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosMembersUserPrivilegesRowDTO
     */
    'key'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosMembersUserPrivilegesRowDTO
     */
    'hasPrivilege'?: boolean;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO
 */
export interface WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosShopMembersShopMemberInvitationRowDto
 */
export interface WorkspacesCommonDtosShopMembersShopMemberInvitationRowDto {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopMembersShopMemberInvitationRowDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopMembersShopMemberInvitationRowDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopMembersShopMemberInvitationRowDto
     */
    'createDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosShopMembersShopMemberInvitationRowDto
     */
    'isAccepted'?: boolean;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosShopMembersShopMemberRowDTO
 */
export interface WorkspacesCommonDtosShopMembersShopMemberRowDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopMembersShopMemberRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopMembersShopMemberRowDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopMembersShopMemberRowDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosShopMembersShopMemberRowDTO
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopMembersShopMemberRowDTO
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosShopsCreateShopDTO
 */
export interface WorkspacesCommonDtosShopsCreateShopDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsCreateShopDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsCreateShopDTO
     */
    'siteAddreess'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsCreateShopDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsCreateShopDTO
     */
    'tell'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsCreateShopDTO
     */
    'address'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosShopsShopRowDTO
 */
export interface WorkspacesCommonDtosShopsShopRowDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'number'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'memberCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'ownerUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'registerAt'?: string;
    /**
     * 
     * @type {WorkspacesCommonEnumsShopsShopStatus}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'status'?: WorkspacesCommonEnumsShopsShopStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsShopRowDTO
     */
    'activeAt'?: string | null;
}


/**
 * 
 * @export
 * @interface WorkspacesCommonDtosShopsUpdateShopDTO
 */
export interface WorkspacesCommonDtosShopsUpdateShopDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsUpdateShopDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsUpdateShopDTO
     */
    'siteAddreess'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsUpdateShopDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsUpdateShopDTO
     */
    'tell': string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsUpdateShopDTO
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsUpdateShopDTO
     */
    'shopId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosShopsUpdateShopDTO
     */
    'ownerUserId'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO
 */
export interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO
     */
    'commentText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO
     */
    'supportTicketId'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO
 */
export interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO
     */
    'ticketText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO
     */
    'departmentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO
     */
    'attachmentNames'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO
 */
export interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO
     */
    'replyText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO
     */
    'supportTicketId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO
     */
    'attachmentNames'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO
 */
export interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO
     */
    'orderProductId'?: string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO
 */
export interface WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO
     */
    'ticketId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsReviewRequestInventoryDTO
 */
export interface WorkspacesCommonDtosSupportTicketsReviewRequestInventoryDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsReviewRequestInventoryDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsReviewRequestInventoryDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsReviewRequestInventoryDTO
     */
    'information'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsReviewRequestInventoryDTO
     */
    'fullInformation'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
 */
export interface WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'fromId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'toId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'userDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'modifyAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'status'?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'isMediationRequested'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO
     */
    'mediationRequestDate'?: string | null;
}


/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
 */
export interface WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'returnReasonCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'modifyAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'mediationRequestDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'handlerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'handlerUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'oppositeShopTitle'?: string | null;
    /**
     * 
     * @type {WorkspacesCommonDtosBaseTitleValueDTO}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'department'?: WorkspacesCommonDtosBaseTitleValueDTO;
    /**
     * 
     * @type {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'status'?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'isGood'?: boolean | null;
    /**
     * 
     * @type {Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO>}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'replies'?: Array<WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO> | null;
    /**
     * 
     * @type {Array<WorkspacesCommonDtosBaseTitleValueDTOSystemGuid>}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'tags'?: Array<WorkspacesCommonDtosBaseTitleValueDTOSystemGuid> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'orderProductId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     * @deprecated
     */
    'inventoryId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'sellerId'?: string;
    /**
     * 
     * @type {WorkspacesCommonDtosSupportTicketsReviewRequestInventoryDTO}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'inventory'?: WorkspacesCommonDtosSupportTicketsReviewRequestInventoryDTO;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'productTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'sellPrice'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'sellerRequestedMoreAmount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'isMediationDone'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'hasBuyerPaidMoreAmount'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO
     */
    'canRequestMediation'?: boolean;
}


/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsSupportTicketCountDTO
 */
export interface WorkspacesCommonDtosSupportTicketsSupportTicketCountDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketCountDTO
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketCountDTO
     */
    'forceCount'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO
 */
export interface WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO
     */
    'userDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsCommentDTO
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
 */
export interface WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO {
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'userDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'attachmentPaths'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'attachmentNames'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'shopId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsSupportTicketDetailsReplyDTO
     */
    'isSystemic'?: boolean;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO
 */
export interface WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO {
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO
     */
    'isGood'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO
     */
    'supportTicketId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WorkspacesCommonEnumsPoliciesPackageStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type WorkspacesCommonEnumsPoliciesPackageStatus = typeof WorkspacesCommonEnumsPoliciesPackageStatus[keyof typeof WorkspacesCommonEnumsPoliciesPackageStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const WorkspacesCommonEnumsShopsShopStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type WorkspacesCommonEnumsShopsShopStatus = typeof WorkspacesCommonEnumsShopsShopStatus[keyof typeof WorkspacesCommonEnumsShopsShopStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const WorkspacesCommonEnumsSupportTicketsSupportTicketStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type WorkspacesCommonEnumsSupportTicketsSupportTicketStatus = typeof WorkspacesCommonEnumsSupportTicketsSupportTicketStatus[keyof typeof WorkspacesCommonEnumsSupportTicketsSupportTicketStatus];


/**
 * 
 * @export
 * @interface WorkspacesCommonQueriesBasePaginatedQueryResponseApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
 */
export interface WorkspacesCommonQueriesBasePaginatedQueryResponseApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow {
    /**
     * 
     * @type {Array<ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow>}
     * @memberof WorkspacesCommonQueriesBasePaginatedQueryResponseApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
     */
    'rows'?: Array<ApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow> | null;
    /**
     * 
     * @type {number}
     * @memberof WorkspacesCommonQueriesBasePaginatedQueryResponseApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow
     */
    'totalRows'?: number;
}
/**
 * 
 * @export
 * @interface WorkspacesCommonQueriesShopsGetShopOverviewQueryResponse
 */
export interface WorkspacesCommonQueriesShopsGetShopOverviewQueryResponse {
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonQueriesShopsGetShopOverviewQueryResponse
     */
    'isOwner'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspacesCommonQueriesShopsGetShopOverviewQueryResponse
     */
    'policies'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspacesCommonQueriesShopsGetShopOverviewQueryResponse
     */
    'isSuspended'?: boolean;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountInformationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Account/Informations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountPost: async (identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosSignInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAccountInformationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAccountInformationsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.identityAccountInformationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAccountPost(identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAccountPost(identityCommonDtosSignInDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.identityAccountPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountInformationsGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto> {
            return localVarFp.identityAccountInformationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountPost(identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityAccountPost(identityCommonDtosSignInDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public identityAccountInformationsGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).identityAccountInformationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public identityAccountPost(identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).identityAccountPost(identityCommonDtosSignInDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceArticleGet: async (categoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceArticleGet(categoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsContentArticlesArticleRowDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceArticleGet(categoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleApi.marketPlaceArticleGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceArticleGet(categoryId?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsContentArticlesArticleRowDTO>> {
            return localVarFp.marketPlaceArticleGet(categoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
    /**
     * 
     * @param {string} [categoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public marketPlaceArticleGet(categoryId?: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).marketPlaceArticleGet(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ArticleCategoryApi - axios parameter creator
 * @export
 */
export const ArticleCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceArticleCategoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/ArticleCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceArticleCategoryTitleValueChildOnlyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/ArticleCategory/TitleValue/ChildOnly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceArticleCategoryTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/ArticleCategory/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleCategoryApi - functional programming interface
 * @export
 */
export const ArticleCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceArticleCategoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceArticleCategoryGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleCategoryApi.marketPlaceArticleCategoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceArticleCategoryTitleValueChildOnlyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceArticleCategoryTitleValueChildOnlyGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleCategoryApi.marketPlaceArticleCategoryTitleValueChildOnlyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceArticleCategoryTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceArticleCategoryTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ArticleCategoryApi.marketPlaceArticleCategoryTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ArticleCategoryApi - factory interface
 * @export
 */
export const ArticleCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceArticleCategoryGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsContentArticleCategoriesArticleCategoryRowDTO>> {
            return localVarFp.marketPlaceArticleCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceArticleCategoryTitleValueChildOnlyGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.marketPlaceArticleCategoryTitleValueChildOnlyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceArticleCategoryTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.marketPlaceArticleCategoryTitleValueGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleCategoryApi - object-oriented interface
 * @export
 * @class ArticleCategoryApi
 * @extends {BaseAPI}
 */
export class ArticleCategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleCategoryApi
     */
    public marketPlaceArticleCategoryGet(options?: AxiosRequestConfig) {
        return ArticleCategoryApiFp(this.configuration).marketPlaceArticleCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleCategoryApi
     */
    public marketPlaceArticleCategoryTitleValueChildOnlyGet(options?: AxiosRequestConfig) {
        return ArticleCategoryApiFp(this.configuration).marketPlaceArticleCategoryTitleValueChildOnlyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleCategoryApi
     */
    public marketPlaceArticleCategoryTitleValueGet(options?: AxiosRequestConfig) {
        return ArticleCategoryApiFp(this.configuration).marketPlaceArticleCategoryTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BuyPlanApi - axios parameter creator
 * @export
 */
export const BuyPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto} [apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPlanDefaultPut: async (apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto?: ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/BuyPlan/Default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPlanFixGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/BuyPlan/Fix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['pageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPlanProductIdGet: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('buyPlanProductIdGet', 'productId', productId)
            const localVarPath = `/BuyPlan/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto} [apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPlanPut: async (apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto?: ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/BuyPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuyPlanApi - functional programming interface
 * @export
 */
export const BuyPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuyPlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto} [apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyPlanDefaultPut(apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto?: ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyPlanDefaultPut(apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyPlanApi.buyPlanDefaultPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyPlanFixGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyPlanFixGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyPlanApi.buyPlanFixGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyPlanProductIdGet(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyPlanProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyPlanApi.buyPlanProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto} [apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyPlanPut(apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto?: ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyPlanPut(apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BuyPlanApi.buyPlanPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BuyPlanApi - factory interface
 * @export
 */
export const BuyPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuyPlanApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto} [apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPlanDefaultPut(apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto?: ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyPlanDefaultPut(apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPlanFixGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyPlanFixGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPlanProductIdGet(productId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto> {
            return localVarFp.buyPlanProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto} [apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyPlanPut(apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto?: ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyPlanPut(apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuyPlanApi - object-oriented interface
 * @export
 * @class BuyPlanApi
 * @extends {BaseAPI}
 */
export class BuyPlanApi extends BaseAPI {
    /**
     * 
     * @param {ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto} [apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyPlanApi
     */
    public buyPlanDefaultPut(apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto?: ApiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto, options?: AxiosRequestConfig) {
        return BuyPlanApiFp(this.configuration).buyPlanDefaultPut(apiGiftCommonDTOsOrdersOrdersUpdateDefaultBuyPlanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyPlanApi
     */
    public buyPlanFixGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return BuyPlanApiFp(this.configuration).buyPlanFixGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyPlanApi
     */
    public buyPlanProductIdGet(productId: string, options?: AxiosRequestConfig) {
        return BuyPlanApiFp(this.configuration).buyPlanProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto} [apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyPlanApi
     */
    public buyPlanPut(apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto?: ApiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto, options?: AxiosRequestConfig) {
        return BuyPlanApiFp(this.configuration).buyPlanPut(apiGiftCommonDTOsOrdersOrdersUpdateBuyPlanDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventoryCategoryApi - axios parameter creator
 * @export
 */
export const InventoryCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryCategoryCategoryIdGet: async (categoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('sellerInventoryCategoryCategoryIdGet', 'categoryId', categoryId)
            const localVarPath = `/Seller/InventoryCategory/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryCategoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryCategoryMultiLevelGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryCategory/MultiLevel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryCategoryPost: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryCategoryApi - functional programming interface
 * @export
 */
export const InventoryCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryCategoryCategoryIdGet(categoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsInventoryInventoryInventoryCategoryDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryCategoryCategoryIdGet(categoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryCategoryApi.sellerInventoryCategoryCategoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryCategoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsInventoryInventorySellerInventoryProductCategoryDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryCategoryGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryCategoryApi.sellerInventoryCategoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryCategoryMultiLevelGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryCategoryMultiLevelGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryCategoryApi.sellerInventoryCategoryMultiLevelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryCategoryPost(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryCategoryPost(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryCategoryApi.sellerInventoryCategoryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InventoryCategoryApi - factory interface
 * @export
 */
export const InventoryCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryCategoryCategoryIdGet(categoryId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsInventoryInventoryInventoryCategoryDetailsDTO> {
            return localVarFp.sellerInventoryCategoryCategoryIdGet(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryCategoryGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsInventoryInventorySellerInventoryProductCategoryDTO>> {
            return localVarFp.sellerInventoryCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryCategoryMultiLevelGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO>> {
            return localVarFp.sellerInventoryCategoryMultiLevelGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryCategoryPost(requestBody?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventoryCategoryPost(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryCategoryApi - object-oriented interface
 * @export
 * @class InventoryCategoryApi
 * @extends {BaseAPI}
 */
export class InventoryCategoryApi extends BaseAPI {
    /**
     * 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoryApi
     */
    public sellerInventoryCategoryCategoryIdGet(categoryId: string, options?: AxiosRequestConfig) {
        return InventoryCategoryApiFp(this.configuration).sellerInventoryCategoryCategoryIdGet(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoryApi
     */
    public sellerInventoryCategoryGet(options?: AxiosRequestConfig) {
        return InventoryCategoryApiFp(this.configuration).sellerInventoryCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoryApi
     */
    public sellerInventoryCategoryMultiLevelGet(options?: AxiosRequestConfig) {
        return InventoryCategoryApiFp(this.configuration).sellerInventoryCategoryMultiLevelGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryCategoryApi
     */
    public sellerInventoryCategoryPost(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return InventoryCategoryApiFp(this.configuration).sellerInventoryCategoryPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventoryProductApi - axios parameter creator
 * @export
 */
export const InventoryProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductAddConditionsProductIdGet: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('sellerInventoryProductAddConditionsProductIdGet', 'productId', productId)
            const localVarPath = `/Seller/InventoryProduct/AddConditions/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductAvailableIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sellerInventoryProductAvailableIdPut', 'id', id)
            const localVarPath = `/Seller/InventoryProduct/Available/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO} [apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sellerInventoryProductChangePut: async (apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO?: ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryProduct/Change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductCustomCountProductIdGet: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('sellerInventoryProductCustomCountProductIdGet', 'productId', productId)
            const localVarPath = `/Seller/InventoryProduct/CustomCount/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString} [apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductDetailsFullExcelProductIdPost: async (productId: string, apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString?: ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('sellerInventoryProductDetailsFullExcelProductIdPost', 'productId', productId)
            const localVarPath = `/Seller/InventoryProduct/Details/Full/Excel/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [shopCollectionId] 
         * @param {string} [orderProductId] 
         * @param {string} [productId] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus} [status] 
         * @param {boolean} [duplicateInformationAccepted] 
         * @param {boolean} [unhandledDuplicateInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductGet: async (shopCollectionId?: string, orderProductId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus, duplicateInformationAccepted?: boolean, unhandledDuplicateInformation?: boolean, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (shopCollectionId !== undefined) {
                localVarQueryParameter['ShopCollectionId'] = shopCollectionId;
            }

            if (orderProductId !== undefined) {
                localVarQueryParameter['OrderProductId'] = orderProductId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (information !== undefined) {
                localVarQueryParameter['Information'] = information;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (duplicateInformationAccepted !== undefined) {
                localVarQueryParameter['DuplicateInformationAccepted'] = duplicateInformationAccepted;
            }

            if (unhandledDuplicateInformation !== undefined) {
                localVarQueryParameter['UnhandledDuplicateInformation'] = unhandledDuplicateInformation;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductHoldCollectionCollectionIdPut: async (collectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('sellerInventoryProductHoldCollectionCollectionIdPut', 'collectionId', collectionId)
            const localVarPath = `/Seller/InventoryProduct/Hold/Collection/{collectionId}`
                .replace(`{${"collectionId"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductHoldIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sellerInventoryProductHoldIdPut', 'id', id)
            const localVarPath = `/Seller/InventoryProduct/Hold/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inventoryShopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductInformationInventoryShopIdGet: async (inventoryShopId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inventoryShopId' is not null or undefined
            assertParamExists('sellerInventoryProductInformationInventoryShopIdGet', 'inventoryShopId', inventoryShopId)
            const localVarPath = `/Seller/InventoryProduct/Information/{inventoryShopId}`
                .replace(`{${"inventoryShopId"}}`, encodeURIComponent(String(inventoryShopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductInventoryIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sellerInventoryProductInventoryIdGet', 'id', id)
            const localVarPath = `/Seller/InventoryProduct/Inventory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO} [apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductItemPost: async (apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO?: ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryProduct/Item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductProductIdGet: async (productId: string, information?: string, status?: ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('sellerInventoryProductProductIdGet', 'productId', productId)
            const localVarPath = `/Seller/InventoryProduct/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (information !== undefined) {
                localVarQueryParameter['Information'] = information;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO} [apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductStatusProductIdPut: async (productId: string, apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO?: ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('sellerInventoryProductStatusProductIdPut', 'productId', productId)
            const localVarPath = `/Seller/InventoryProduct/Status/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto} [apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductUnlockIdPut: async (id: string, apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto?: ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sellerInventoryProductUnlockIdPut', 'id', id)
            const localVarPath = `/Seller/InventoryProduct/Unlock/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryProductApi - functional programming interface
 * @export
 */
export const InventoryProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductAddConditionsProductIdGet(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsInventoryInventoryInventoryAddConditionsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductAddConditionsProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductAddConditionsProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductAvailableIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductAvailableIdPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductAvailableIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO} [apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async sellerInventoryProductChangePut(apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO?: ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductChangePut(apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductChangePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductCustomCountProductIdGet(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductCustomCountProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductCustomCountProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString} [apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductDetailsFullExcelProductIdPost(productId: string, apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString?: ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductDetailsFullExcelProductIdPost(productId, apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductDetailsFullExcelProductIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [shopCollectionId] 
         * @param {string} [orderProductId] 
         * @param {string} [productId] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus} [status] 
         * @param {boolean} [duplicateInformationAccepted] 
         * @param {boolean} [unhandledDuplicateInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductGet(shopCollectionId?: string, orderProductId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus, duplicateInformationAccepted?: boolean, unhandledDuplicateInformation?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductGet(shopCollectionId, orderProductId, productId, information, status, duplicateInformationAccepted, unhandledDuplicateInformation, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductHoldCollectionCollectionIdPut(collectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductHoldCollectionCollectionIdPut(collectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductHoldCollectionCollectionIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductHoldIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductHoldIdPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductHoldIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inventoryShopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductInformationInventoryShopIdGet(inventoryShopId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsInventoryInventoryShopInventoriesInventorySecretInformationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductInformationInventoryShopIdGet(inventoryShopId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductInformationInventoryShopIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductInventoryIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductInventoryIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductInventoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO} [apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductItemPost(apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO?: ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductItemPost(apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductItemPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductProductIdGet(productId: string, information?: string, status?: ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductProductIdGet(productId, information, status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO} [apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductStatusProductIdPut(productId: string, apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO?: ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductStatusProductIdPut(productId, apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductStatusProductIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto} [apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryProductUnlockIdPut(id: string, apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto?: ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryProductUnlockIdPut(id, apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryProductApi.sellerInventoryProductUnlockIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InventoryProductApi - factory interface
 * @export
 */
export const InventoryProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryProductApiFp(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductAddConditionsProductIdGet(productId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsInventoryInventoryInventoryAddConditionsDTO> {
            return localVarFp.sellerInventoryProductAddConditionsProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductAvailableIdPut(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventoryProductAvailableIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO} [apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sellerInventoryProductChangePut(apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO?: ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventoryProductChangePut(apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductCustomCountProductIdGet(productId: string, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.sellerInventoryProductCustomCountProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString} [apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductDetailsFullExcelProductIdPost(productId: string, apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString?: ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventoryProductDetailsFullExcelProductIdPost(productId, apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [shopCollectionId] 
         * @param {string} [orderProductId] 
         * @param {string} [productId] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus} [status] 
         * @param {boolean} [duplicateInformationAccepted] 
         * @param {boolean} [unhandledDuplicateInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductGet(shopCollectionId?: string, orderProductId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus, duplicateInformationAccepted?: boolean, unhandledDuplicateInformation?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO> {
            return localVarFp.sellerInventoryProductGet(shopCollectionId, orderProductId, productId, information, status, duplicateInformationAccepted, unhandledDuplicateInformation, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductHoldCollectionCollectionIdPut(collectionId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventoryProductHoldCollectionCollectionIdPut(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductHoldIdPut(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventoryProductHoldIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inventoryShopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductInformationInventoryShopIdGet(inventoryShopId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsInventoryInventoryShopInventoriesInventorySecretInformationDto> {
            return localVarFp.sellerInventoryProductInformationInventoryShopIdGet(inventoryShopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductInventoryIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO> {
            return localVarFp.sellerInventoryProductInventoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO} [apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductItemPost(apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO?: ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventoryProductItemPost(apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductProductIdGet(productId: string, information?: string, status?: ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryShopInventoriesShopInventoryRowDTO> {
            return localVarFp.sellerInventoryProductProductIdGet(productId, information, status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO} [apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductStatusProductIdPut(productId: string, apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO?: ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventoryProductStatusProductIdPut(productId, apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto} [apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryProductUnlockIdPut(id: string, apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto?: ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventoryProductUnlockIdPut(id, apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryProductApi - object-oriented interface
 * @export
 * @class InventoryProductApi
 * @extends {BaseAPI}
 */
export class InventoryProductApi extends BaseAPI {
    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductAddConditionsProductIdGet(productId: string, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductAddConditionsProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductAvailableIdPut(id: string, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductAvailableIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO} [apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductChangePut(apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO?: ApiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductChangePut(apiGiftCommonDTOsInventoryInventoryInventoryProductChangeInventoryProductDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductCustomCountProductIdGet(productId: string, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductCustomCountProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString} [apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductDetailsFullExcelProductIdPost(productId: string, apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString?: ApiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductDetailsFullExcelProductIdPost(productId, apiGiftCommonQueryStringsInventoryGetProductInventoryDetailsQueryString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [shopCollectionId] 
     * @param {string} [orderProductId] 
     * @param {string} [productId] 
     * @param {string} [information] 
     * @param {ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus} [status] 
     * @param {boolean} [duplicateInformationAccepted] 
     * @param {boolean} [unhandledDuplicateInformation] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductGet(shopCollectionId?: string, orderProductId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus, duplicateInformationAccepted?: boolean, unhandledDuplicateInformation?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductGet(shopCollectionId, orderProductId, productId, information, status, duplicateInformationAccepted, unhandledDuplicateInformation, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductHoldCollectionCollectionIdPut(collectionId: string, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductHoldCollectionCollectionIdPut(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductHoldIdPut(id: string, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductHoldIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inventoryShopId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductInformationInventoryShopIdGet(inventoryShopId: string, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductInformationInventoryShopIdGet(inventoryShopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductInventoryIdGet(id: string, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductInventoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO} [apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductItemPost(apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO?: ApiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductItemPost(apiGiftCommonDTOsInventoryShopInventoriesAddShopInventoryDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} [information] 
     * @param {ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductProductIdGet(productId: string, information?: string, status?: ApiGiftCommonEnumsInventoryInventoryShopsShopInventoryStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductProductIdGet(productId, information, status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO} [apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductStatusProductIdPut(productId: string, apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO?: ApiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductStatusProductIdPut(productId, apiGiftCommonDTOsInventoryInventoryUpdateInventoryConfigStatusDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto} [apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryProductApi
     */
    public sellerInventoryProductUnlockIdPut(id: string, apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto?: ApiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto, options?: AxiosRequestConfig) {
        return InventoryProductApiFp(this.configuration).sellerInventoryProductUnlockIdPut(id, apiGiftGatewayCommonDTOsInventoriesUnlockInventoryDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventoryShopCollectionApi - axios parameter creator
 * @export
 */
export const InventoryShopCollectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO} [apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionChangeDeficitPut: async (apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO?: ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryShopCollection/ChangeDeficit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionDeficitsCountGet: async (productId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryShopCollection/Deficits/Count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionDeficitsGet: async (productId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryShopCollection/Deficits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionDeficitsProductIdGet: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('sellerInventoryShopCollectionDeficitsProductIdGet', 'productId', productId)
            const localVarPath = `/Seller/InventoryShopCollection/Deficits/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionGet: async (orderNumber?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryShopCollection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderNumber !== undefined) {
                localVarQueryParameter['OrderNumber'] = orderNumber;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {string} [sourceId] 
         * @param {string} [orderNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionOrderNumberGet: async (productId?: string, sourceId?: string, orderNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventoryShopCollection/OrderNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (sourceId !== undefined) {
                localVarQueryParameter['SourceId'] = sourceId;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['OrderNumber'] = orderNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryShopCollectionApi - functional programming interface
 * @export
 */
export const InventoryShopCollectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryShopCollectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO} [apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryShopCollectionChangeDeficitPut(apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO?: ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryShopCollectionChangeDeficitPut(apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryShopCollectionApi.sellerInventoryShopCollectionChangeDeficitPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryShopCollectionDeficitsCountGet(productId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryShopCollectionDeficitsCountGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryShopCollectionApi.sellerInventoryShopCollectionDeficitsCountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryShopCollectionDeficitsGet(productId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryShopCollectionDeficitsGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryShopCollectionApi.sellerInventoryShopCollectionDeficitsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryShopCollectionDeficitsProductIdGet(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsInventoryInventoryInventoryDeficitDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryShopCollectionDeficitsProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryShopCollectionApi.sellerInventoryShopCollectionDeficitsProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryShopCollectionGet(orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryShopCollectionGet(orderNumber, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryShopCollectionApi.sellerInventoryShopCollectionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {string} [sourceId] 
         * @param {string} [orderNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventoryShopCollectionOrderNumberGet(productId?: string, sourceId?: string, orderNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsInventoryInventoryShopInventoriesUniqueSellerOrderNumberDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventoryShopCollectionOrderNumberGet(productId, sourceId, orderNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryShopCollectionApi.sellerInventoryShopCollectionOrderNumberGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InventoryShopCollectionApi - factory interface
 * @export
 */
export const InventoryShopCollectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryShopCollectionApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO} [apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionChangeDeficitPut(apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO?: ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventoryShopCollectionChangeDeficitPut(apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionDeficitsCountGet(productId?: string, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.sellerInventoryShopCollectionDeficitsCountGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionDeficitsGet(productId?: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionDeficitRowDTO> {
            return localVarFp.sellerInventoryShopCollectionDeficitsGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionDeficitsProductIdGet(productId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsInventoryInventoryInventoryDeficitDTO>> {
            return localVarFp.sellerInventoryShopCollectionDeficitsProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionGet(orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryShopCollectionInventoryShopCollectionRowDTO> {
            return localVarFp.sellerInventoryShopCollectionGet(orderNumber, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productId] 
         * @param {string} [sourceId] 
         * @param {string} [orderNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventoryShopCollectionOrderNumberGet(productId?: string, sourceId?: string, orderNumber?: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsInventoryInventoryShopInventoriesUniqueSellerOrderNumberDTO> {
            return localVarFp.sellerInventoryShopCollectionOrderNumberGet(productId, sourceId, orderNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryShopCollectionApi - object-oriented interface
 * @export
 * @class InventoryShopCollectionApi
 * @extends {BaseAPI}
 */
export class InventoryShopCollectionApi extends BaseAPI {
    /**
     * 
     * @param {ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO} [apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryShopCollectionApi
     */
    public sellerInventoryShopCollectionChangeDeficitPut(apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO?: ApiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO, options?: AxiosRequestConfig) {
        return InventoryShopCollectionApiFp(this.configuration).sellerInventoryShopCollectionChangeDeficitPut(apiGiftCommonDTOsInventoryInventoryShopCollectionUpdateDeficitDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryShopCollectionApi
     */
    public sellerInventoryShopCollectionDeficitsCountGet(productId?: string, options?: AxiosRequestConfig) {
        return InventoryShopCollectionApiFp(this.configuration).sellerInventoryShopCollectionDeficitsCountGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryShopCollectionApi
     */
    public sellerInventoryShopCollectionDeficitsGet(productId?: string, options?: AxiosRequestConfig) {
        return InventoryShopCollectionApiFp(this.configuration).sellerInventoryShopCollectionDeficitsGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryShopCollectionApi
     */
    public sellerInventoryShopCollectionDeficitsProductIdGet(productId: string, options?: AxiosRequestConfig) {
        return InventoryShopCollectionApiFp(this.configuration).sellerInventoryShopCollectionDeficitsProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderNumber] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryShopCollectionApi
     */
    public sellerInventoryShopCollectionGet(orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return InventoryShopCollectionApiFp(this.configuration).sellerInventoryShopCollectionGet(orderNumber, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productId] 
     * @param {string} [sourceId] 
     * @param {string} [orderNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryShopCollectionApi
     */
    public sellerInventoryShopCollectionOrderNumberGet(productId?: string, sourceId?: string, orderNumber?: string, options?: AxiosRequestConfig) {
        return InventoryShopCollectionApiFp(this.configuration).sellerInventoryShopCollectionOrderNumberGet(productId, sourceId, orderNumber, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventorySourceApi - axios parameter creator
 * @export
 */
export const InventorySourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceGet: async (pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventorySource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceInventorySourceIdDelete: async (inventorySourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inventorySourceId' is not null or undefined
            assertParamExists('sellerInventorySourceInventorySourceIdDelete', 'inventorySourceId', inventorySourceId)
            const localVarPath = `/Seller/InventorySource/{inventorySourceId}`
                .replace(`{${"inventorySourceId"}}`, encodeURIComponent(String(inventorySourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceInventorySourceIdGet: async (inventorySourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inventorySourceId' is not null or undefined
            assertParamExists('sellerInventorySourceInventorySourceIdGet', 'inventorySourceId', inventorySourceId)
            const localVarPath = `/Seller/InventorySource/{inventorySourceId}`
                .replace(`{${"inventorySourceId"}}`, encodeURIComponent(String(inventorySourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO} [apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourcePost: async (apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO?: ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventorySource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO} [apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourcePut: async (apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO?: ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventorySource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventorySource/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTitleValuesDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventorySource/TitleValues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceUpdateInventorySourceIdGet: async (inventorySourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inventorySourceId' is not null or undefined
            assertParamExists('sellerInventorySourceUpdateInventorySourceIdGet', 'inventorySourceId', inventorySourceId)
            const localVarPath = `/Seller/InventorySource/Update/{inventorySourceId}`
                .replace(`{${"inventorySourceId"}}`, encodeURIComponent(String(inventorySourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventorySourceApi - functional programming interface
 * @export
 */
export const InventorySourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventorySourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceGet(pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceApi.sellerInventorySourceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceInventorySourceIdDelete(inventorySourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceInventorySourceIdDelete(inventorySourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceApi.sellerInventorySourceInventorySourceIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceInventorySourceIdGet(inventorySourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceApi.sellerInventorySourceInventorySourceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO} [apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourcePost(apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO?: ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourcePost(apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceApi.sellerInventorySourcePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO} [apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourcePut(apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO?: ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourcePut(apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceApi.sellerInventorySourcePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceApi.sellerInventorySourceTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceTitleValuesDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceTitleValuesDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceApi.sellerInventorySourceTitleValuesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceUpdateInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceUpdateInventorySourceIdGet(inventorySourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceApi.sellerInventorySourceUpdateInventorySourceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InventorySourceApi - factory interface
 * @export
 */
export const InventorySourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventorySourceApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventorySourcesInventorySourceRowDTO> {
            return localVarFp.sellerInventorySourceGet(pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceInventorySourceIdDelete(inventorySourceId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventorySourceInventorySourceIdDelete(inventorySourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsInventoryInventorySourcesInventorySourceDetailsDTO> {
            return localVarFp.sellerInventorySourceInventorySourceIdGet(inventorySourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO} [apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourcePost(apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO?: ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventorySourcePost(apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO} [apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourcePut(apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO?: ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventorySourcePut(apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.sellerInventorySourceTitleValueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTitleValuesDelete(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.sellerInventorySourceTitleValuesDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceUpdateInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO> {
            return localVarFp.sellerInventorySourceUpdateInventorySourceIdGet(inventorySourceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventorySourceApi - object-oriented interface
 * @export
 * @class InventorySourceApi
 * @extends {BaseAPI}
 */
export class InventorySourceApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceApi
     */
    public sellerInventorySourceGet(pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return InventorySourceApiFp(this.configuration).sellerInventorySourceGet(pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inventorySourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceApi
     */
    public sellerInventorySourceInventorySourceIdDelete(inventorySourceId: string, options?: AxiosRequestConfig) {
        return InventorySourceApiFp(this.configuration).sellerInventorySourceInventorySourceIdDelete(inventorySourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inventorySourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceApi
     */
    public sellerInventorySourceInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig) {
        return InventorySourceApiFp(this.configuration).sellerInventorySourceInventorySourceIdGet(inventorySourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO} [apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceApi
     */
    public sellerInventorySourcePost(apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO?: ApiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO, options?: AxiosRequestConfig) {
        return InventorySourceApiFp(this.configuration).sellerInventorySourcePost(apiGiftCommonDTOsInventoryInventorySourcesCreateInventorySourceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO} [apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceApi
     */
    public sellerInventorySourcePut(apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO?: ApiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO, options?: AxiosRequestConfig) {
        return InventorySourceApiFp(this.configuration).sellerInventorySourcePut(apiGiftCommonDTOsInventoryInventorySourcesUpdateInventorySourceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceApi
     */
    public sellerInventorySourceTitleValueGet(options?: AxiosRequestConfig) {
        return InventorySourceApiFp(this.configuration).sellerInventorySourceTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceApi
     */
    public sellerInventorySourceTitleValuesDelete(options?: AxiosRequestConfig) {
        return InventorySourceApiFp(this.configuration).sellerInventorySourceTitleValuesDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inventorySourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceApi
     */
    public sellerInventorySourceUpdateInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig) {
        return InventorySourceApiFp(this.configuration).sellerInventorySourceUpdateInventorySourceIdGet(inventorySourceId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventorySourceTransactionApi - axios parameter creator
 * @export
 */
export const InventorySourceTransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionCurrenciesTitleValueInventorySourceIdGet: async (inventorySourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inventorySourceId' is not null or undefined
            assertParamExists('sellerInventorySourceTransactionCurrenciesTitleValueInventorySourceIdGet', 'inventorySourceId', inventorySourceId)
            const localVarPath = `/Seller/InventorySourceTransaction/Currencies/TitleValue/{inventorySourceId}`
                .replace(`{${"inventorySourceId"}}`, encodeURIComponent(String(inventorySourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionCurrencyBalancesInventorySourceIdGet: async (inventorySourceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inventorySourceId' is not null or undefined
            assertParamExists('sellerInventorySourceTransactionCurrencyBalancesInventorySourceIdGet', 'inventorySourceId', inventorySourceId)
            const localVarPath = `/Seller/InventorySourceTransaction/CurrencyBalances/{inventorySourceId}`
                .replace(`{${"inventorySourceId"}}`, encodeURIComponent(String(inventorySourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [inventorySourceId] 
         * @param {string} [currencyId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionGet: async (inventorySourceId?: string, currencyId?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventorySourceTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (inventorySourceId !== undefined) {
                localVarQueryParameter['InventorySourceId'] = inventorySourceId;
            }

            if (currencyId !== undefined) {
                localVarQueryParameter['CurrencyId'] = currencyId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inventorySourceTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionInventorySourceTransactionIdDelete: async (inventorySourceTransactionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inventorySourceTransactionId' is not null or undefined
            assertParamExists('sellerInventorySourceTransactionInventorySourceTransactionIdDelete', 'inventorySourceTransactionId', inventorySourceTransactionId)
            const localVarPath = `/Seller/InventorySourceTransaction/{inventorySourceTransactionId}`
                .replace(`{${"inventorySourceTransactionId"}}`, encodeURIComponent(String(inventorySourceTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} inventorySourceTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionInventorySourceTransactionIdGet: async (inventorySourceTransactionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inventorySourceTransactionId' is not null or undefined
            assertParamExists('sellerInventorySourceTransactionInventorySourceTransactionIdGet', 'inventorySourceTransactionId', inventorySourceTransactionId)
            const localVarPath = `/Seller/InventorySourceTransaction/{inventorySourceTransactionId}`
                .replace(`{${"inventorySourceTransactionId"}}`, encodeURIComponent(String(inventorySourceTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO} [apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionPost: async (apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO?: ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventorySourceTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO} [apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionPut: async (apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO?: ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/InventorySourceTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventorySourceTransactionApi - functional programming interface
 * @export
 */
export const InventorySourceTransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventorySourceTransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async sellerInventorySourceTransactionCurrenciesTitleValueInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemDouble>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceTransactionCurrenciesTitleValueInventorySourceIdGet(inventorySourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceTransactionApi.sellerInventorySourceTransactionCurrenciesTitleValueInventorySourceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceTransactionCurrencyBalancesInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsInventoryInventorySourceTransactionsCurrencyInventorySourceTransactionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceTransactionCurrencyBalancesInventorySourceIdGet(inventorySourceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceTransactionApi.sellerInventorySourceTransactionCurrencyBalancesInventorySourceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [inventorySourceId] 
         * @param {string} [currencyId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceTransactionGet(inventorySourceId?: string, currencyId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceTransactionGet(inventorySourceId, currencyId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceTransactionApi.sellerInventorySourceTransactionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inventorySourceTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceTransactionInventorySourceTransactionIdDelete(inventorySourceTransactionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceTransactionInventorySourceTransactionIdDelete(inventorySourceTransactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceTransactionApi.sellerInventorySourceTransactionInventorySourceTransactionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} inventorySourceTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceTransactionInventorySourceTransactionIdGet(inventorySourceTransactionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceTransactionInventorySourceTransactionIdGet(inventorySourceTransactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceTransactionApi.sellerInventorySourceTransactionInventorySourceTransactionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO} [apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceTransactionPost(apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO?: ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceTransactionPost(apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceTransactionApi.sellerInventorySourceTransactionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO} [apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerInventorySourceTransactionPut(apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO?: ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerInventorySourceTransactionPut(apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventorySourceTransactionApi.sellerInventorySourceTransactionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InventorySourceTransactionApi - factory interface
 * @export
 */
export const InventorySourceTransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventorySourceTransactionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionCurrenciesTitleValueInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemDouble>> {
            return localVarFp.sellerInventorySourceTransactionCurrenciesTitleValueInventorySourceIdGet(inventorySourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inventorySourceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionCurrencyBalancesInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsInventoryInventorySourceTransactionsCurrencyInventorySourceTransactionDTO>> {
            return localVarFp.sellerInventorySourceTransactionCurrencyBalancesInventorySourceIdGet(inventorySourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [inventorySourceId] 
         * @param {string} [currencyId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionGet(inventorySourceId?: string, currencyId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryInventorySourceTransactionInventorySourceTransactionRowDTO> {
            return localVarFp.sellerInventorySourceTransactionGet(inventorySourceId, currencyId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inventorySourceTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionInventorySourceTransactionIdDelete(inventorySourceTransactionId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventorySourceTransactionInventorySourceTransactionIdDelete(inventorySourceTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} inventorySourceTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionInventorySourceTransactionIdGet(inventorySourceTransactionId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO> {
            return localVarFp.sellerInventorySourceTransactionInventorySourceTransactionIdGet(inventorySourceTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO} [apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionPost(apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO?: ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventorySourceTransactionPost(apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO} [apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerInventorySourceTransactionPut(apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO?: ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerInventorySourceTransactionPut(apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventorySourceTransactionApi - object-oriented interface
 * @export
 * @class InventorySourceTransactionApi
 * @extends {BaseAPI}
 */
export class InventorySourceTransactionApi extends BaseAPI {
    /**
     * 
     * @param {string} inventorySourceId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InventorySourceTransactionApi
     */
    public sellerInventorySourceTransactionCurrenciesTitleValueInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig) {
        return InventorySourceTransactionApiFp(this.configuration).sellerInventorySourceTransactionCurrenciesTitleValueInventorySourceIdGet(inventorySourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inventorySourceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceTransactionApi
     */
    public sellerInventorySourceTransactionCurrencyBalancesInventorySourceIdGet(inventorySourceId: string, options?: AxiosRequestConfig) {
        return InventorySourceTransactionApiFp(this.configuration).sellerInventorySourceTransactionCurrencyBalancesInventorySourceIdGet(inventorySourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [inventorySourceId] 
     * @param {string} [currencyId] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceTransactionApi
     */
    public sellerInventorySourceTransactionGet(inventorySourceId?: string, currencyId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return InventorySourceTransactionApiFp(this.configuration).sellerInventorySourceTransactionGet(inventorySourceId, currencyId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inventorySourceTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceTransactionApi
     */
    public sellerInventorySourceTransactionInventorySourceTransactionIdDelete(inventorySourceTransactionId: string, options?: AxiosRequestConfig) {
        return InventorySourceTransactionApiFp(this.configuration).sellerInventorySourceTransactionInventorySourceTransactionIdDelete(inventorySourceTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} inventorySourceTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceTransactionApi
     */
    public sellerInventorySourceTransactionInventorySourceTransactionIdGet(inventorySourceTransactionId: string, options?: AxiosRequestConfig) {
        return InventorySourceTransactionApiFp(this.configuration).sellerInventorySourceTransactionInventorySourceTransactionIdGet(inventorySourceTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO} [apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceTransactionApi
     */
    public sellerInventorySourceTransactionPost(apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO?: ApiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO, options?: AxiosRequestConfig) {
        return InventorySourceTransactionApiFp(this.configuration).sellerInventorySourceTransactionPost(apiGiftCommonDTOsInventoryInventorySourceTransactionsCreateInventorySourceTransactionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO} [apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventorySourceTransactionApi
     */
    public sellerInventorySourceTransactionPut(apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO?: ApiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO, options?: AxiosRequestConfig) {
        return InventorySourceTransactionApiFp(this.configuration).sellerInventorySourceTransactionPut(apiGiftCommonDTOsInventoryInventorySourceTransactionsUpdateInventorySourceTransactionDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InventoryStatisticsApi - axios parameter creator
 * @export
 */
export const InventoryStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryStatisticsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/InventoryStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryStatisticsApi - functional programming interface
 * @export
 */
export const InventoryStatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryStatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryStatisticsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsInventoryInventoryInventoryFinancialStatisticsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryStatisticsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InventoryStatisticsApi.inventoryStatisticsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InventoryStatisticsApi - factory interface
 * @export
 */
export const InventoryStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryStatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryStatisticsGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsInventoryInventoryInventoryFinancialStatisticsDTO> {
            return localVarFp.inventoryStatisticsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryStatisticsApi - object-oriented interface
 * @export
 * @class InventoryStatisticsApi
 * @extends {BaseAPI}
 */
export class InventoryStatisticsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryStatisticsApi
     */
    public inventoryStatisticsGet(options?: AxiosRequestConfig) {
        return InventoryStatisticsApiFp(this.configuration).inventoryStatisticsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonEnumsBaseMessageChannel} [channel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceNotificationGet: async (channel?: ApiGiftGatewayCommonEnumsBaseMessageChannel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceNotificationSeenAllPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Notification/SeenAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceNotificationUnseenCountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Notification/Unseen/Count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonEnumsBaseMessageChannel} [channel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceNotificationGet(channel?: ApiGiftGatewayCommonEnumsBaseMessageChannel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftDomainInterfacesNotificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceNotificationGet(channel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.marketPlaceNotificationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceNotificationSeenAllPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceNotificationSeenAllPut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.marketPlaceNotificationSeenAllPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceNotificationUnseenCountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceNotificationUnseenCountGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.marketPlaceNotificationUnseenCountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonEnumsBaseMessageChannel} [channel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceNotificationGet(channel?: ApiGiftGatewayCommonEnumsBaseMessageChannel, options?: AxiosRequestConfig): AxiosPromise<ApiGiftDomainInterfacesNotificationResponse> {
            return localVarFp.marketPlaceNotificationGet(channel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceNotificationSeenAllPut(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.marketPlaceNotificationSeenAllPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceNotificationUnseenCountGet(options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.marketPlaceNotificationUnseenCountGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {ApiGiftGatewayCommonEnumsBaseMessageChannel} [channel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public marketPlaceNotificationGet(channel?: ApiGiftGatewayCommonEnumsBaseMessageChannel, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).marketPlaceNotificationGet(channel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public marketPlaceNotificationSeenAllPut(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).marketPlaceNotificationSeenAllPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public marketPlaceNotificationUnseenCountGet(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).marketPlaceNotificationUnseenCountGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationPermissionApi - axios parameter creator
 * @export
 */
export const NotificationPermissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceNotificationPermissionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/NotificationPermission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>} [apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceNotificationPermissionPut: async (apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO?: Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/NotificationPermission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationPermissionApi - functional programming interface
 * @export
 */
export const NotificationPermissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationPermissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceNotificationPermissionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceNotificationPermissionGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationPermissionApi.marketPlaceNotificationPermissionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>} [apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO?: Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationPermissionApi.marketPlaceNotificationPermissionPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationPermissionApi - factory interface
 * @export
 */
export const NotificationPermissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationPermissionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceNotificationPermissionGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsNotificationPermissionRowDTO>> {
            return localVarFp.marketPlaceNotificationPermissionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>} [apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO?: Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.marketPlaceNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationPermissionApi - object-oriented interface
 * @export
 * @class NotificationPermissionApi
 * @extends {BaseAPI}
 */
export class NotificationPermissionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationPermissionApi
     */
    public marketPlaceNotificationPermissionGet(options?: AxiosRequestConfig) {
        return NotificationPermissionApiFp(this.configuration).marketPlaceNotificationPermissionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>} [apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationPermissionApi
     */
    public marketPlaceNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO?: Array<ApiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO>, options?: AxiosRequestConfig) {
        return NotificationPermissionApiFp(this.configuration).marketPlaceNotificationPermissionPut(apiGiftGatewayCommonDTOsMarketplaceNotificationsUpdateNotificationPermissionDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerBuyGet: async (categoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Offer/Buy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerBuyPaginationGet: async (pageSkip?: number, pageSize?: number, categoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Offer/Buy/Pagination`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerCancelIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerCancelIdDelete', 'id', id)
            const localVarPath = `/Offer/Cancel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {string} [productId] 
         * @param {string} [productCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGet: async (status?: Array<number>, pageSkip?: number, pageSize?: number, productId?: string, productCategoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (productCategoryId !== undefined) {
                localVarQueryParameter['ProductCategoryId'] = productCategoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto} [apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerPost: async (apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto?: ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerBuyGet(categoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsOrdersOfferRowDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerBuyGet(categoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferApi.offerBuyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerBuyPaginationGet(pageSkip?: number, pageSize?: number, categoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsOrdersOfferRowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerBuyPaginationGet(pageSkip, pageSize, categoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferApi.offerBuyPaginationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerCancelIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerCancelIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferApi.offerCancelIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<number>} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {string} [productId] 
         * @param {string} [productCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerGet(status?: Array<number>, pageSkip?: number, pageSize?: number, productId?: string, productCategoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerGet(status, pageSkip, pageSize, productId, productCategoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferApi.offerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto} [apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerPost(apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto?: ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerPost(apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OfferApi.offerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerBuyGet(categoryId?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsOrdersOfferRowDto>> {
            return localVarFp.offerBuyGet(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerBuyPaginationGet(pageSkip?: number, pageSize?: number, categoryId?: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftGatewayCommonDTOsOrdersOfferRowDto> {
            return localVarFp.offerBuyPaginationGet(pageSkip, pageSize, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerCancelIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.offerCancelIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {string} [productId] 
         * @param {string} [productCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerGet(status?: Array<number>, pageSkip?: number, pageSize?: number, productId?: string, productCategoryId?: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.offerGet(status, pageSkip, pageSize, productId, productCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto} [apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerPost(apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto?: ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.offerPost(apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @param {string} [categoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerBuyGet(categoryId?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerBuyGet(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {string} [categoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerBuyPaginationGet(pageSkip?: number, pageSize?: number, categoryId?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerBuyPaginationGet(pageSkip, pageSize, categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerCancelIdDelete(id: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerCancelIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {string} [productId] 
     * @param {string} [productCategoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerGet(status?: Array<number>, pageSkip?: number, pageSize?: number, productId?: string, productCategoryId?: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerGet(status, pageSkip, pageSize, productId, productCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto} [apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerPost(apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto?: ApiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerPost(apiGiftGatewayCommonDTOsOrdersCreateBuyOfferDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderDetailsFullExcelOrderIdGet: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('buyerOrderDetailsFullExcelOrderIdGet', 'orderId', orderId)
            const localVarPath = `/Buyer/Order/Details/Full/Excel/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [information] 
         * @param {string} [title] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {boolean} [isSold] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderDetailsFullGet: async (orderId?: string, information?: string, title?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, isSold?: boolean, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/Order/Details/Full`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (information !== undefined) {
                localVarQueryParameter['Information'] = information;
            }

            if (title !== undefined) {
                localVarQueryParameter['Title'] = title;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (isSold !== undefined) {
                localVarQueryParameter['IsSold'] = isSold;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [information] 
         * @param {string} [title] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {boolean} [isSold] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderDetailsGet: async (orderId?: string, information?: string, title?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, isSold?: boolean, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/Order/Details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (information !== undefined) {
                localVarQueryParameter['Information'] = information;
            }

            if (title !== undefined) {
                localVarQueryParameter['Title'] = title;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (isSold !== undefined) {
                localVarQueryParameter['IsSold'] = isSold;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [haveNotSoldOnly] 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderGet: async (haveNotSoldOnly?: boolean, orderNumber?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/Order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (haveNotSoldOnly !== undefined) {
                localVarQueryParameter['HaveNotSoldOnly'] = haveNotSoldOnly;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['OrderNumber'] = orderNumber;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderOrderIdGet: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('buyerOrderOrderIdGet', 'orderId', orderId)
            const localVarPath = `/Buyer/Order/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto} [apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderPost: async (apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto?: ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/Order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceOrderRelativeStatisticsBuyShopIdGet: async (shopId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('marketPlaceOrderRelativeStatisticsBuyShopIdGet', 'shopId', shopId)
            const localVarPath = `/MarketPlace/Order/RelativeStatistics/Buy/{shopId}`
                .replace(`{${"shopId"}}`, encodeURIComponent(String(shopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceOrderRelativeStatisticsSaleShopIdGet: async (shopId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('marketPlaceOrderRelativeStatisticsSaleShopIdGet', 'shopId', shopId)
            const localVarPath = `/MarketPlace/Order/RelativeStatistics/Sale/{shopId}`
                .replace(`{${"shopId"}}`, encodeURIComponent(String(shopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceOrderStatisticsShopIdGet: async (shopId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('marketPlaceOrderStatisticsShopIdGet', 'shopId', shopId)
            const localVarPath = `/MarketPlace/Order/Statistics/{shopId}`
                .replace(`{${"shopId"}}`, encodeURIComponent(String(shopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [productId] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderDetailsFullGet: async (orderId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/Order/Details/Full`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (information !== undefined) {
                localVarQueryParameter['Information'] = information;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [productId] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderDetailsGet: async (orderId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/Order/Details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (information !== undefined) {
                localVarQueryParameter['Information'] = information;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
         * @param {string} [orderNumber] 
         * @param {string} [productId] 
         * @param {string} [buyerName] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderGet: async (status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, orderNumber?: string, productId?: string, buyerName?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/Order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['OrderNumber'] = orderNumber;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (buyerName !== undefined) {
                localVarQueryParameter['BuyerName'] = buyerName;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderOrderIdGet: async (orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('sellerOrderOrderIdGet', 'orderId', orderId)
            const localVarPath = `/Seller/Order/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderDetailsFullExcelOrderIdGet(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderDetailsFullExcelOrderIdGet(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.buyerOrderDetailsFullExcelOrderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [information] 
         * @param {string} [title] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {boolean} [isSold] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderDetailsFullGet(orderId?: string, information?: string, title?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, isSold?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderDetailsFullGet(orderId, information, title, status, isSold, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.buyerOrderDetailsFullGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [information] 
         * @param {string} [title] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {boolean} [isSold] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderDetailsGet(orderId?: string, information?: string, title?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, isSold?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderDetailsGet(orderId, information, title, status, isSold, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.buyerOrderDetailsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [haveNotSoldOnly] 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderGet(haveNotSoldOnly?: boolean, orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderGet(haveNotSoldOnly, orderNumber, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.buyerOrderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderOrderIdGet(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderOrderIdGet(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.buyerOrderOrderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto} [apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderPost(apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto?: ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderPost(apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.buyerOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceOrderRelativeStatisticsBuyShopIdGet(shopId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrdersOrderRelativeStatisticsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceOrderRelativeStatisticsBuyShopIdGet(shopId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.marketPlaceOrderRelativeStatisticsBuyShopIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceOrderRelativeStatisticsSaleShopIdGet(shopId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrdersOrderRelativeStatisticsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceOrderRelativeStatisticsSaleShopIdGet(shopId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.marketPlaceOrderRelativeStatisticsSaleShopIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceOrderStatisticsShopIdGet(shopId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrdersOrderStatisticsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceOrderStatisticsShopIdGet(shopId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.marketPlaceOrderStatisticsShopIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [productId] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderDetailsFullGet(orderId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderDetailsFullGet(orderId, productId, information, status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.sellerOrderDetailsFullGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [productId] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderDetailsGet(orderId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderDetailsGet(orderId, productId, information, status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.sellerOrderDetailsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
         * @param {string} [orderNumber] 
         * @param {string} [productId] 
         * @param {string} [buyerName] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderGet(status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, orderNumber?: string, productId?: string, buyerName?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderGet(status, orderNumber, productId, buyerName, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.sellerOrderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderOrderIdGet(orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderOrderIdGet(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderApi.sellerOrderOrderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderDetailsFullExcelOrderIdGet(orderId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerOrderDetailsFullExcelOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [information] 
         * @param {string} [title] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {boolean} [isSold] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderDetailsFullGet(orderId?: string, information?: string, title?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, isSold?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO> {
            return localVarFp.buyerOrderDetailsFullGet(orderId, information, title, status, isSold, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [information] 
         * @param {string} [title] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {boolean} [isSold] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderDetailsGet(orderId?: string, information?: string, title?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, isSold?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderProductDetailsDTO> {
            return localVarFp.buyerOrderDetailsGet(orderId, information, title, status, isSold, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [haveNotSoldOnly] 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderGet(haveNotSoldOnly?: boolean, orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO> {
            return localVarFp.buyerOrderGet(haveNotSoldOnly, orderNumber, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderOrderIdGet(orderId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerOrderOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto} [apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderPost(apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto?: ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.buyerOrderPost(apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceOrderRelativeStatisticsBuyShopIdGet(shopId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrdersOrderRelativeStatisticsDto> {
            return localVarFp.marketPlaceOrderRelativeStatisticsBuyShopIdGet(shopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceOrderRelativeStatisticsSaleShopIdGet(shopId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrdersOrderRelativeStatisticsDto> {
            return localVarFp.marketPlaceOrderRelativeStatisticsSaleShopIdGet(shopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceOrderStatisticsShopIdGet(shopId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrdersOrderStatisticsDto> {
            return localVarFp.marketPlaceOrderStatisticsShopIdGet(shopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [productId] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderDetailsFullGet(orderId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO> {
            return localVarFp.sellerOrderDetailsFullGet(orderId, productId, information, status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {string} [productId] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderDetailsGet(orderId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderProductDetailsRowDTO> {
            return localVarFp.sellerOrderDetailsGet(orderId, productId, information, status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
         * @param {string} [orderNumber] 
         * @param {string} [productId] 
         * @param {string} [buyerName] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderGet(status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, orderNumber?: string, productId?: string, buyerName?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersSellerOrderRowDTO> {
            return localVarFp.sellerOrderGet(status, orderNumber, productId, buyerName, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderOrderIdGet(orderId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrdersSellerOrderDetailsDTO> {
            return localVarFp.sellerOrderOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public buyerOrderDetailsFullExcelOrderIdGet(orderId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).buyerOrderDetailsFullExcelOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderId] 
     * @param {string} [information] 
     * @param {string} [title] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
     * @param {boolean} [isSold] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public buyerOrderDetailsFullGet(orderId?: string, information?: string, title?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, isSold?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).buyerOrderDetailsFullGet(orderId, information, title, status, isSold, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderId] 
     * @param {string} [information] 
     * @param {string} [title] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
     * @param {boolean} [isSold] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public buyerOrderDetailsGet(orderId?: string, information?: string, title?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, isSold?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).buyerOrderDetailsGet(orderId, information, title, status, isSold, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [haveNotSoldOnly] 
     * @param {string} [orderNumber] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public buyerOrderGet(haveNotSoldOnly?: boolean, orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).buyerOrderGet(haveNotSoldOnly, orderNumber, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public buyerOrderOrderIdGet(orderId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).buyerOrderOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto} [apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public buyerOrderPost(apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto?: ApiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).buyerOrderPost(apiGiftCommonDTOsOrdersOrdersCreateOrderFromBasketDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shopId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public marketPlaceOrderRelativeStatisticsBuyShopIdGet(shopId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).marketPlaceOrderRelativeStatisticsBuyShopIdGet(shopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shopId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public marketPlaceOrderRelativeStatisticsSaleShopIdGet(shopId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).marketPlaceOrderRelativeStatisticsSaleShopIdGet(shopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shopId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public marketPlaceOrderStatisticsShopIdGet(shopId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).marketPlaceOrderStatisticsShopIdGet(shopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderId] 
     * @param {string} [productId] 
     * @param {string} [information] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public sellerOrderDetailsFullGet(orderId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).sellerOrderDetailsFullGet(orderId, productId, information, status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [orderId] 
     * @param {string} [productId] 
     * @param {string} [information] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public sellerOrderDetailsGet(orderId?: string, productId?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).sellerOrderDetailsGet(orderId, productId, information, status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
     * @param {string} [orderNumber] 
     * @param {string} [productId] 
     * @param {string} [buyerName] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public sellerOrderGet(status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, orderNumber?: string, productId?: string, buyerName?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).sellerOrderGet(status, orderNumber, productId, buyerName, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public sellerOrderOrderIdGet(orderId: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).sellerOrderOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrderItemApi - axios parameter creator
 * @export
 */
export const OrderItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [inventorySafeInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemSellGet: async (inventorySafeInformation?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OrderItem/Sell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (inventorySafeInformation !== undefined) {
                localVarQueryParameter['InventorySafeInformation'] = inventorySafeInformation;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderItemApi - functional programming interface
 * @export
 */
export const OrderItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [inventorySafeInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderItemSellGet(inventorySafeInformation?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonQueriesBasePaginatedQueryResponseApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderItemSellGet(inventorySafeInformation, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderItemApi.orderItemSellGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrderItemApi - factory interface
 * @export
 */
export const OrderItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderItemApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [inventorySafeInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderItemSellGet(inventorySafeInformation?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonQueriesBasePaginatedQueryResponseApiGiftCommonQueriesOrdersOrderItemsGetPaginatedSellOrderItemsQueryResponseTableRow> {
            return localVarFp.orderItemSellGet(inventorySafeInformation, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderItemApi - object-oriented interface
 * @export
 * @class OrderItemApi
 * @extends {BaseAPI}
 */
export class OrderItemApi extends BaseAPI {
    /**
     * 
     * @param {string} [inventorySafeInformation] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderItemApi
     */
    public orderItemSellGet(inventorySafeInformation?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderItemApiFp(this.configuration).orderItemSellGet(inventorySafeInformation, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrderItemReportApi - axios parameter creator
 * @export
 */
export const OrderItemReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [productId] 
         * @param {string} [orderNumber] 
         * @param {string} [title] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [orderType] 
         * @param {string} [deliveryDate] 
         * @param {boolean} [withFullInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderItemReportExcelGet: async (fromDate?: string, toDate?: string, productId?: string, orderNumber?: string, title?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, orderType?: ApiGiftCommonEnumsOrdersOrdersOrderType, deliveryDate?: string, withFullInformation?: boolean, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/OrderItemReport/Excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['OrderNumber'] = orderNumber;
            }

            if (title !== undefined) {
                localVarQueryParameter['Title'] = title;
            }

            if (information !== undefined) {
                localVarQueryParameter['Information'] = information;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['OrderType'] = orderType;
            }

            if (deliveryDate !== undefined) {
                localVarQueryParameter['DeliveryDate'] = (deliveryDate as any instanceof Date) ?
                    (deliveryDate as any).toISOString() :
                    deliveryDate;
            }

            if (withFullInformation !== undefined) {
                localVarQueryParameter['WithFullInformation'] = withFullInformation;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [productId] 
         * @param {string} [orderNumber] 
         * @param {string} [title] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [orderType] 
         * @param {string} [deliveryDate] 
         * @param {boolean} [withFullInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderItemReportGet: async (fromDate?: string, toDate?: string, productId?: string, orderNumber?: string, title?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, orderType?: ApiGiftCommonEnumsOrdersOrdersOrderType, deliveryDate?: string, withFullInformation?: boolean, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/OrderItemReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['OrderNumber'] = orderNumber;
            }

            if (title !== undefined) {
                localVarQueryParameter['Title'] = title;
            }

            if (information !== undefined) {
                localVarQueryParameter['Information'] = information;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['OrderType'] = orderType;
            }

            if (deliveryDate !== undefined) {
                localVarQueryParameter['DeliveryDate'] = (deliveryDate as any instanceof Date) ?
                    (deliveryDate as any).toISOString() :
                    deliveryDate;
            }

            if (withFullInformation !== undefined) {
                localVarQueryParameter['WithFullInformation'] = withFullInformation;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderItemReportApi - functional programming interface
 * @export
 */
export const OrderItemReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderItemReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [productId] 
         * @param {string} [orderNumber] 
         * @param {string} [title] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [orderType] 
         * @param {string} [deliveryDate] 
         * @param {boolean} [withFullInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderItemReportExcelGet(fromDate?: string, toDate?: string, productId?: string, orderNumber?: string, title?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, orderType?: ApiGiftCommonEnumsOrdersOrdersOrderType, deliveryDate?: string, withFullInformation?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderItemReportExcelGet(fromDate, toDate, productId, orderNumber, title, information, status, orderType, deliveryDate, withFullInformation, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderItemReportApi.buyerOrderItemReportExcelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [productId] 
         * @param {string} [orderNumber] 
         * @param {string} [title] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [orderType] 
         * @param {string} [deliveryDate] 
         * @param {boolean} [withFullInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderItemReportGet(fromDate?: string, toDate?: string, productId?: string, orderNumber?: string, title?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, orderType?: ApiGiftCommonEnumsOrdersOrdersOrderType, deliveryDate?: string, withFullInformation?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderItemReportGet(fromDate, toDate, productId, orderNumber, title, information, status, orderType, deliveryDate, withFullInformation, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderItemReportApi.buyerOrderItemReportGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrderItemReportApi - factory interface
 * @export
 */
export const OrderItemReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderItemReportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [productId] 
         * @param {string} [orderNumber] 
         * @param {string} [title] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [orderType] 
         * @param {string} [deliveryDate] 
         * @param {boolean} [withFullInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderItemReportExcelGet(fromDate?: string, toDate?: string, productId?: string, orderNumber?: string, title?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, orderType?: ApiGiftCommonEnumsOrdersOrdersOrderType, deliveryDate?: string, withFullInformation?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerOrderItemReportExcelGet(fromDate, toDate, productId, orderNumber, title, information, status, orderType, deliveryDate, withFullInformation, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [productId] 
         * @param {string} [orderNumber] 
         * @param {string} [title] 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [orderType] 
         * @param {string} [deliveryDate] 
         * @param {boolean} [withFullInformation] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderItemReportGet(fromDate?: string, toDate?: string, productId?: string, orderNumber?: string, title?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, orderType?: ApiGiftCommonEnumsOrdersOrdersOrderType, deliveryDate?: string, withFullInformation?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO> {
            return localVarFp.buyerOrderItemReportGet(fromDate, toDate, productId, orderNumber, title, information, status, orderType, deliveryDate, withFullInformation, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderItemReportApi - object-oriented interface
 * @export
 * @class OrderItemReportApi
 * @extends {BaseAPI}
 */
export class OrderItemReportApi extends BaseAPI {
    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [productId] 
     * @param {string} [orderNumber] 
     * @param {string} [title] 
     * @param {string} [information] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [orderType] 
     * @param {string} [deliveryDate] 
     * @param {boolean} [withFullInformation] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderItemReportApi
     */
    public buyerOrderItemReportExcelGet(fromDate?: string, toDate?: string, productId?: string, orderNumber?: string, title?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, orderType?: ApiGiftCommonEnumsOrdersOrdersOrderType, deliveryDate?: string, withFullInformation?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderItemReportApiFp(this.configuration).buyerOrderItemReportExcelGet(fromDate, toDate, productId, orderNumber, title, information, status, orderType, deliveryDate, withFullInformation, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [productId] 
     * @param {string} [orderNumber] 
     * @param {string} [title] 
     * @param {string} [information] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [orderType] 
     * @param {string} [deliveryDate] 
     * @param {boolean} [withFullInformation] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderItemReportApi
     */
    public buyerOrderItemReportGet(fromDate?: string, toDate?: string, productId?: string, orderNumber?: string, title?: string, information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, orderType?: ApiGiftCommonEnumsOrdersOrdersOrderType, deliveryDate?: string, withFullInformation?: boolean, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderItemReportApiFp(this.configuration).buyerOrderItemReportGet(fromDate, toDate, productId, orderNumber, title, information, status, orderType, deliveryDate, withFullInformation, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrderItemsApi - axios parameter creator
 * @export
 */
export const OrderItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        orderItemsRefundDeliveredIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('orderItemsRefundDeliveredIdPut', 'id', id)
            const localVarPath = `/OrderItems/Refund/Delivered/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderItemsApi - functional programming interface
 * @export
 */
export const OrderItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async orderItemsRefundDeliveredIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderItemsRefundDeliveredIdPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderItemsApi.orderItemsRefundDeliveredIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrderItemsApi - factory interface
 * @export
 */
export const OrderItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        orderItemsRefundDeliveredIdPut(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.orderItemsRefundDeliveredIdPut(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderItemsApi - object-oriented interface
 * @export
 * @class OrderItemsApi
 * @extends {BaseAPI}
 */
export class OrderItemsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrderItemsApi
     */
    public orderItemsRefundDeliveredIdPut(id: string, options?: AxiosRequestConfig) {
        return OrderItemsApiFp(this.configuration).orderItemsRefundDeliveredIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrderProductApi - axios parameter creator
 * @export
 */
export const OrderProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {string} [title] 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductFullInformationGet: async (information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, title?: string, orderNumber?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/OrderProduct/FullInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (information !== undefined) {
                localVarQueryParameter['Information'] = information;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (title !== undefined) {
                localVarQueryParameter['Title'] = title;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['OrderNumber'] = orderNumber;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {string} [title] 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductGet: async (information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, title?: string, orderNumber?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/OrderProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (information !== undefined) {
                localVarQueryParameter['Information'] = information;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (title !== undefined) {
                localVarQueryParameter['Title'] = title;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['OrderNumber'] = orderNumber;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductMarkAsSoldGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/OrderProduct/MarkAsSold`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO} [apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductMarkAsSoldPut: async (apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO?: ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/OrderProduct/MarkAsSold`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderProductId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO} [apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductPayMoreOrderProductIdPut: async (orderProductId: string, apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderProductId' is not null or undefined
            assertParamExists('buyerOrderProductPayMoreOrderProductIdPut', 'orderProductId', orderProductId)
            const localVarPath = `/Buyer/OrderProduct/PayMore/{orderProductId}`
                .replace(`{${"orderProductId"}}`, encodeURIComponent(String(orderProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductSupportPost: async (workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/OrderProduct/Support`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductCountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/OrderProduct/Count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductCountPendingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/OrderProduct/Count/Pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductCountPendingProductIdGet: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('sellerOrderProductCountPendingProductIdGet', 'productId', productId)
            const localVarPath = `/Seller/OrderProduct/Count/Pending/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO>} [apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductOrderSuggestionHandlePut: async (apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO?: Array<ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/OrderProduct/OrderSuggestion/Handle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderProductId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO} [apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductPayMoreOrderProductIdPost: async (orderProductId: string, apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderProductId' is not null or undefined
            assertParamExists('sellerOrderProductPayMoreOrderProductIdPost', 'orderProductId', orderProductId)
            const localVarPath = `/Seller/OrderProduct/PayMore/{orderProductId}`
                .replace(`{${"orderProductId"}}`, encodeURIComponent(String(orderProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductStatisticsProductIdGet: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('sellerOrderProductStatisticsProductIdGet', 'productId', productId)
            const localVarPath = `/Seller/OrderProduct/Statistics/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderProductApi - functional programming interface
 * @export
 */
export const OrderProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {string} [title] 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderProductFullInformationGet(information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, title?: string, orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderProductFullInformationGet(information, status, title, orderNumber, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.buyerOrderProductFullInformationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {string} [title] 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderProductGet(information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, title?: string, orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderProductGet(information, status, title, orderNumber, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.buyerOrderProductGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderProductMarkAsSoldGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrdersBuyerNotSoldOrderCountDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderProductMarkAsSoldGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.buyerOrderProductMarkAsSoldGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO} [apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderProductMarkAsSoldPut(apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO?: ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderProductMarkAsSoldPut(apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.buyerOrderProductMarkAsSoldPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderProductId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO} [apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderProductPayMoreOrderProductIdPut(orderProductId: string, apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderProductPayMoreOrderProductIdPut(orderProductId, apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.buyerOrderProductPayMoreOrderProductIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderProductSupportPost(workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderProductSupportPost(workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.buyerOrderProductSupportPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderProductCountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrdersSellersOrderCountDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderProductCountGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.sellerOrderProductCountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderProductCountPendingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrdersSellersOrderCountDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderProductCountPendingGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.sellerOrderProductCountPendingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderProductCountPendingProductIdGet(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrdersSellersOrderCountDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderProductCountPendingProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.sellerOrderProductCountPendingProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO>} [apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderProductOrderSuggestionHandlePut(apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO?: Array<ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderProductOrderSuggestionHandlePut(apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.sellerOrderProductOrderSuggestionHandlePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderProductId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO} [apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderProductPayMoreOrderProductIdPost(orderProductId: string, apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderProductPayMoreOrderProductIdPost(orderProductId, apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.sellerOrderProductPayMoreOrderProductIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderProductStatisticsProductIdGet(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrdersSellersOrderStatisticsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderProductStatisticsProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderProductApi.sellerOrderProductStatisticsProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrderProductApi - factory interface
 * @export
 */
export const OrderProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderProductApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {string} [title] 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductFullInformationGet(information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, title?: string, orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO> {
            return localVarFp.buyerOrderProductFullInformationGet(information, status, title, orderNumber, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [information] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
         * @param {string} [title] 
         * @param {string} [orderNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductGet(information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, title?: string, orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrderProductsBuyerOrderProductRowDTO> {
            return localVarFp.buyerOrderProductGet(information, status, title, orderNumber, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductMarkAsSoldGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrdersBuyerNotSoldOrderCountDTO> {
            return localVarFp.buyerOrderProductMarkAsSoldGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO} [apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductMarkAsSoldPut(apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO?: ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerOrderProductMarkAsSoldPut(apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderProductId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO} [apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductPayMoreOrderProductIdPut(orderProductId: string, apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerOrderProductPayMoreOrderProductIdPut(orderProductId, apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderProductSupportPost(workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerOrderProductSupportPost(workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductCountGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrdersSellersOrderCountDTO> {
            return localVarFp.sellerOrderProductCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductCountPendingGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrdersSellersOrderCountDTO> {
            return localVarFp.sellerOrderProductCountPendingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductCountPendingProductIdGet(productId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrdersSellersOrderCountDTO> {
            return localVarFp.sellerOrderProductCountPendingProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO>} [apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductOrderSuggestionHandlePut(apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO?: Array<ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO>, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerOrderProductOrderSuggestionHandlePut(apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderProductId 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO} [apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductPayMoreOrderProductIdPost(orderProductId: string, apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerOrderProductPayMoreOrderProductIdPost(orderProductId, apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderProductStatisticsProductIdGet(productId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrdersSellersOrderStatisticsDTO> {
            return localVarFp.sellerOrderProductStatisticsProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderProductApi - object-oriented interface
 * @export
 * @class OrderProductApi
 * @extends {BaseAPI}
 */
export class OrderProductApi extends BaseAPI {
    /**
     * 
     * @param {string} [information] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
     * @param {string} [title] 
     * @param {string} [orderNumber] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public buyerOrderProductFullInformationGet(information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, title?: string, orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).buyerOrderProductFullInformationGet(information, status, title, orderNumber, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [information] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderProductStatus} [status] 
     * @param {string} [title] 
     * @param {string} [orderNumber] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public buyerOrderProductGet(information?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, title?: string, orderNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).buyerOrderProductGet(information, status, title, orderNumber, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public buyerOrderProductMarkAsSoldGet(options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).buyerOrderProductMarkAsSoldGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO} [apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public buyerOrderProductMarkAsSoldPut(apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO?: ApiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).buyerOrderProductMarkAsSoldPut(apiGiftCommonDTOsOrdersOrderProductsMarkAsSoldOrderProductDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderProductId 
     * @param {ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO} [apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public buyerOrderProductPayMoreOrderProductIdPut(orderProductId: string, apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).buyerOrderProductPayMoreOrderProductIdPut(orderProductId, apiGiftGatewayCommonDTOsFinancialWalletsHandleRequestAmountForProductDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public buyerOrderProductSupportPost(workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).buyerOrderProductSupportPost(workspacesCommonDtosSupportTicketsCreateSupportTicketReportDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public sellerOrderProductCountGet(options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).sellerOrderProductCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public sellerOrderProductCountPendingGet(options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).sellerOrderProductCountPendingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public sellerOrderProductCountPendingProductIdGet(productId: string, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).sellerOrderProductCountPendingProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO>} [apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public sellerOrderProductOrderSuggestionHandlePut(apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO?: Array<ApiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO>, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).sellerOrderProductOrderSuggestionHandlePut(apiGiftCommonDTOsOrdersOrdersOrderSuggestionUpdateSettingDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderProductId 
     * @param {ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO} [apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public sellerOrderProductPayMoreOrderProductIdPost(orderProductId: string, apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO?: ApiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).sellerOrderProductPayMoreOrderProductIdPost(orderProductId, apiGiftGatewayCommonDTOsFinancialWalletsRequestAmountForProductDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderProductApi
     */
    public sellerOrderProductStatisticsProductIdGet(productId: string, options?: AxiosRequestConfig) {
        return OrderProductApiFp(this.configuration).sellerOrderProductStatisticsProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrderReportApi - axios parameter creator
 * @export
 */
export const OrderReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [type] 
         * @param {string} [orderNumber] 
         * @param {string} [productId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderReportExcelGet: async (fromDate?: string, toDate?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, type?: ApiGiftCommonEnumsOrdersOrdersOrderType, orderNumber?: string, productId?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/OrderReport/Excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['OrderNumber'] = orderNumber;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [type] 
         * @param {string} [orderNumber] 
         * @param {string} [productId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderReportGet: async (fromDate?: string, toDate?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, type?: ApiGiftCommonEnumsOrdersOrdersOrderType, orderNumber?: string, productId?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/OrderReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['OrderNumber'] = orderNumber;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderReportApi - functional programming interface
 * @export
 */
export const OrderReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [type] 
         * @param {string} [orderNumber] 
         * @param {string} [productId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderReportExcelGet(fromDate?: string, toDate?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, type?: ApiGiftCommonEnumsOrdersOrdersOrderType, orderNumber?: string, productId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderReportExcelGet(fromDate, toDate, status, type, orderNumber, productId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderReportApi.buyerOrderReportExcelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [type] 
         * @param {string} [orderNumber] 
         * @param {string} [productId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerOrderReportGet(fromDate?: string, toDate?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, type?: ApiGiftCommonEnumsOrdersOrdersOrderType, orderNumber?: string, productId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerOrderReportGet(fromDate, toDate, status, type, orderNumber, productId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderReportApi.buyerOrderReportGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrderReportApi - factory interface
 * @export
 */
export const OrderReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderReportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [type] 
         * @param {string} [orderNumber] 
         * @param {string} [productId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderReportExcelGet(fromDate?: string, toDate?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, type?: ApiGiftCommonEnumsOrdersOrdersOrderType, orderNumber?: string, productId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerOrderReportExcelGet(fromDate, toDate, status, type, orderNumber, productId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
         * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [type] 
         * @param {string} [orderNumber] 
         * @param {string} [productId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerOrderReportGet(fromDate?: string, toDate?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, type?: ApiGiftCommonEnumsOrdersOrdersOrderType, orderNumber?: string, productId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsOrdersOrdersBuyerOrderRowDTO> {
            return localVarFp.buyerOrderReportGet(fromDate, toDate, status, type, orderNumber, productId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderReportApi - object-oriented interface
 * @export
 * @class OrderReportApi
 * @extends {BaseAPI}
 */
export class OrderReportApi extends BaseAPI {
    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [type] 
     * @param {string} [orderNumber] 
     * @param {string} [productId] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReportApi
     */
    public buyerOrderReportExcelGet(fromDate?: string, toDate?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, type?: ApiGiftCommonEnumsOrdersOrdersOrderType, orderNumber?: string, productId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderReportApiFp(this.configuration).buyerOrderReportExcelGet(fromDate, toDate, status, type, orderNumber, productId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderStatus} [status] 
     * @param {ApiGiftCommonEnumsOrdersOrdersOrderType} [type] 
     * @param {string} [orderNumber] 
     * @param {string} [productId] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderReportApi
     */
    public buyerOrderReportGet(fromDate?: string, toDate?: string, status?: ApiGiftCommonEnumsOrdersOrdersOrderStatus, type?: ApiGiftCommonEnumsOrdersOrdersOrderType, orderNumber?: string, productId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return OrderReportApiFp(this.configuration).buyerOrderReportGet(fromDate, toDate, status, type, orderNumber, productId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrderingPlanApi - axios parameter creator
 * @export
 */
export const OrderingPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO} [apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderingPlanPost: async (apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO?: ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/OrderingPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderingPlanProductIdGet: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('sellerOrderingPlanProductIdGet', 'productId', productId)
            const localVarPath = `/Seller/OrderingPlan/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderingPlanApi - functional programming interface
 * @export
 */
export const OrderingPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderingPlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO} [apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderingPlanPost(apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO?: ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderingPlanPost(apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderingPlanApi.sellerOrderingPlanPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerOrderingPlanProductIdGet(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerOrderingPlanProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrderingPlanApi.sellerOrderingPlanProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrderingPlanApi - factory interface
 * @export
 */
export const OrderingPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderingPlanApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO} [apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderingPlanPost(apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO?: ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerOrderingPlanPost(apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerOrderingPlanProductIdGet(productId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrderPlanOrderingPlanDTO> {
            return localVarFp.sellerOrderingPlanProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderingPlanApi - object-oriented interface
 * @export
 * @class OrderingPlanApi
 * @extends {BaseAPI}
 */
export class OrderingPlanApi extends BaseAPI {
    /**
     * 
     * @param {ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO} [apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderingPlanApi
     */
    public sellerOrderingPlanPost(apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO?: ApiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO, options?: AxiosRequestConfig) {
        return OrderingPlanApiFp(this.configuration).sellerOrderingPlanPost(apiGiftCommonDTOsOrdersOrderPlanUpdateOrderPlanConfigDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderingPlanApi
     */
    public sellerOrderingPlanProductIdGet(productId: string, options?: AxiosRequestConfig) {
        return OrderingPlanApiFp(this.configuration).sellerOrderingPlanProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PasswordApi - axios parameter creator
 * @export
 */
export const PasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotPost: async (returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Password/Forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosForgotPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotVerifyPost: async (returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Password/Forgot/Verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosVerifyForgotPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordResetPost: async (identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Password/Reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosResetPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordApi - functional programming interface
 * @export
 */
export const PasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityPasswordForgotPost(returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityPasswordForgotPost(returnURL, identityCommonDtosForgotPasswordDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordApi.identityPasswordForgotPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityPasswordForgotVerifyPost(returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityPasswordForgotVerifyPost(returnURL, identityCommonDtosVerifyForgotPasswordDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordApi.identityPasswordForgotVerifyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityPasswordResetPost(identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityPasswordResetPost(identityCommonDtosResetPasswordDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordApi.identityPasswordResetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PasswordApi - factory interface
 * @export
 */
export const PasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotPost(returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityPasswordForgotPost(returnURL, identityCommonDtosForgotPasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotVerifyPost(returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityPasswordForgotVerifyPost(returnURL, identityCommonDtosVerifyForgotPasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordResetPost(identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityPasswordResetPost(identityCommonDtosResetPasswordDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordApi - object-oriented interface
 * @export
 * @class PasswordApi
 * @extends {BaseAPI}
 */
export class PasswordApi extends BaseAPI {
    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public identityPasswordForgotPost(returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).identityPasswordForgotPost(returnURL, identityCommonDtosForgotPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public identityPasswordForgotVerifyPost(returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).identityPasswordForgotVerifyPost(returnURL, identityCommonDtosVerifyForgotPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public identityPasswordResetPost(identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).identityPasswordResetPost(identityCommonDtosResetPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/Product/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sellerShopId] 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceProductAvailableCountGet: async (sellerShopId?: string, productId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Product/AvailableCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sellerShopId !== undefined) {
                localVarQueryParameter['SellerShopId'] = sellerShopId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [productCategoryId] 
         * @param {string} [productId] 
         * @param {string} [search] 
         * @param {string} [productTitle] 
         * @param {string} [sortBy] 
         * @param {boolean} [onlyAvailable] 
         * @param {Array<ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus>} [statusFilter] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceProductGet: async (productCategoryId?: string, productId?: string, search?: string, productTitle?: string, sortBy?: string, onlyAvailable?: boolean, statusFilter?: Array<ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus>, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productCategoryId !== undefined) {
                localVarQueryParameter['ProductCategoryId'] = productCategoryId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (productTitle !== undefined) {
                localVarQueryParameter['ProductTitle'] = productTitle;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (onlyAvailable !== undefined) {
                localVarQueryParameter['OnlyAvailable'] = onlyAvailable;
            }

            if (statusFilter) {
                localVarQueryParameter['StatusFilter'] = statusFilter;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceProductPricesProductIdGet: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('marketPlaceProductPricesProductIdGet', 'productId', productId)
            const localVarPath = `/MarketPlace/Product/Prices/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductCategoryIdGet: async (categoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('sellerProductCategoryIdGet', 'categoryId', categoryId)
            const localVarPath = `/Seller/Product/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductStructureTitleValueProductIdGet: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('sellerProductStructureTitleValueProductIdGet', 'productId', productId)
            const localVarPath = `/Seller/Product/Structure/TitleValue/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductTitleValueCategoryIdGet: async (categoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('sellerProductTitleValueCategoryIdGet', 'categoryId', categoryId)
            const localVarPath = `/Seller/Product/TitleValue/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerProductTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerProductTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.buyerProductTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [sellerShopId] 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceProductAvailableCountGet(sellerShopId?: string, productId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonQueriesInventoryInventoryGetInventoryAvailableCountDetailsQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceProductAvailableCountGet(sellerShopId, productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.marketPlaceProductAvailableCountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [productCategoryId] 
         * @param {string} [productId] 
         * @param {string} [search] 
         * @param {string} [productTitle] 
         * @param {string} [sortBy] 
         * @param {boolean} [onlyAvailable] 
         * @param {Array<ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus>} [statusFilter] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceProductGet(productCategoryId?: string, productId?: string, search?: string, productTitle?: string, sortBy?: string, onlyAvailable?: boolean, statusFilter?: Array<ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus>, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonQueriesHybridGetPagedProductsQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceProductGet(productCategoryId, productId, search, productTitle, sortBy, onlyAvailable, statusFilter, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.marketPlaceProductGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceProductPricesProductIdGet(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceProductPricesProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.marketPlaceProductPricesProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerProductCategoryIdGet(categoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonQueryStringsShopsProductProductListDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerProductCategoryIdGet(categoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.sellerProductCategoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerProductStructureTitleValueProductIdGet(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemString>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerProductStructureTitleValueProductIdGet(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.sellerProductStructureTitleValueProductIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerProductTitleValueCategoryIdGet(categoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerProductTitleValueCategoryIdGet(categoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.sellerProductTitleValueCategoryIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid>> {
            return localVarFp.buyerProductTitleValueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sellerShopId] 
         * @param {string} [productId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceProductAvailableCountGet(sellerShopId?: string, productId?: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonQueriesInventoryInventoryGetInventoryAvailableCountDetailsQueryResponse> {
            return localVarFp.marketPlaceProductAvailableCountGet(sellerShopId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [productCategoryId] 
         * @param {string} [productId] 
         * @param {string} [search] 
         * @param {string} [productTitle] 
         * @param {string} [sortBy] 
         * @param {boolean} [onlyAvailable] 
         * @param {Array<ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus>} [statusFilter] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceProductGet(productCategoryId?: string, productId?: string, search?: string, productTitle?: string, sortBy?: string, onlyAvailable?: boolean, statusFilter?: Array<ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus>, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonQueriesHybridGetPagedProductsQueryResponse> {
            return localVarFp.marketPlaceProductGet(productCategoryId, productId, search, productTitle, sortBy, onlyAvailable, statusFilter, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceProductPricesProductIdGet(productId: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonQueriesHybridGetProductBuyPricesQueryResponse> {
            return localVarFp.marketPlaceProductPricesProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductCategoryIdGet(categoryId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonQueryStringsShopsProductProductListDTO>> {
            return localVarFp.sellerProductCategoryIdGet(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductStructureTitleValueProductIdGet(productId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemString>> {
            return localVarFp.sellerProductStructureTitleValueProductIdGet(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductTitleValueCategoryIdGet(categoryId: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.sellerProductTitleValueCategoryIdGet(categoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public buyerProductTitleValueGet(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).buyerProductTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sellerShopId] 
     * @param {string} [productId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public marketPlaceProductAvailableCountGet(sellerShopId?: string, productId?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).marketPlaceProductAvailableCountGet(sellerShopId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [productCategoryId] 
     * @param {string} [productId] 
     * @param {string} [search] 
     * @param {string} [productTitle] 
     * @param {string} [sortBy] 
     * @param {boolean} [onlyAvailable] 
     * @param {Array<ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus>} [statusFilter] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public marketPlaceProductGet(productCategoryId?: string, productId?: string, search?: string, productTitle?: string, sortBy?: string, onlyAvailable?: boolean, statusFilter?: Array<ApiGiftGatewayCommonEnumsInventoryProductGetPagedProductFilterStatus>, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).marketPlaceProductGet(productCategoryId, productId, search, productTitle, sortBy, onlyAvailable, statusFilter, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public marketPlaceProductPricesProductIdGet(productId: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).marketPlaceProductPricesProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public sellerProductCategoryIdGet(categoryId: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).sellerProductCategoryIdGet(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public sellerProductStructureTitleValueProductIdGet(productId: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).sellerProductStructureTitleValueProductIdGet(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public sellerProductTitleValueCategoryIdGet(categoryId: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).sellerProductTitleValueCategoryIdGet(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductBasketApi - axios parameter creator
 * @export
 */
export const ProductBasketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductBasketGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ProductBasket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO} [apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductBasketPost: async (apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO?: ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ProductBasket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productBasketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductBasketProductBasketIdDelete: async (productBasketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productBasketId' is not null or undefined
            assertParamExists('buyerProductBasketProductBasketIdDelete', 'productBasketId', productBasketId)
            const localVarPath = `/Buyer/ProductBasket/{productBasketId}`
                .replace(`{${"productBasketId"}}`, encodeURIComponent(String(productBasketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO} [apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductBasketPut: async (apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO?: ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ProductBasket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductBasketApi - functional programming interface
 * @export
 */
export const ProductBasketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductBasketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerProductBasketGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerProductBasketGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductBasketApi.buyerProductBasketGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO} [apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerProductBasketPost(apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO?: ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerProductBasketPost(apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductBasketApi.buyerProductBasketPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productBasketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerProductBasketProductBasketIdDelete(productBasketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerProductBasketProductBasketIdDelete(productBasketId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductBasketApi.buyerProductBasketProductBasketIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO} [apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerProductBasketPut(apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO?: ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerProductBasketPut(apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductBasketApi.buyerProductBasketPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductBasketApi - factory interface
 * @export
 */
export const ProductBasketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductBasketApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductBasketGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsOrdersProductBasketsProductBasketRowDTO>> {
            return localVarFp.buyerProductBasketGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO} [apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductBasketPost(apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO?: ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerProductBasketPost(apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productBasketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductBasketProductBasketIdDelete(productBasketId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerProductBasketProductBasketIdDelete(productBasketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO} [apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductBasketPut(apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO?: ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerProductBasketPut(apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductBasketApi - object-oriented interface
 * @export
 * @class ProductBasketApi
 * @extends {BaseAPI}
 */
export class ProductBasketApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBasketApi
     */
    public buyerProductBasketGet(options?: AxiosRequestConfig) {
        return ProductBasketApiFp(this.configuration).buyerProductBasketGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO} [apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBasketApi
     */
    public buyerProductBasketPost(apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO?: ApiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO, options?: AxiosRequestConfig) {
        return ProductBasketApiFp(this.configuration).buyerProductBasketPost(apiGiftCommonDTOsOrdersProductBasketsCreateProductBasketDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productBasketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBasketApi
     */
    public buyerProductBasketProductBasketIdDelete(productBasketId: string, options?: AxiosRequestConfig) {
        return ProductBasketApiFp(this.configuration).buyerProductBasketProductBasketIdDelete(productBasketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO} [apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductBasketApi
     */
    public buyerProductBasketPut(apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO?: ApiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO, options?: AxiosRequestConfig) {
        return ProductBasketApiFp(this.configuration).buyerProductBasketPut(apiGiftCommonDTOsOrdersProductBasketsUpdateProductBasketDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductCatalogApi - axios parameter creator
 * @export
 */
export const ProductCatalogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [productCategoryId] 
         * @param {string} [productId] 
         * @param {string} [productTitleSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCatalogExpressGet: async (productCategoryId?: string, productId?: string, productTitleSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProductCatalog/Express`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productCategoryId !== undefined) {
                localVarQueryParameter['ProductCategoryId'] = productCategoryId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['ProductId'] = productId;
            }

            if (productTitleSearch !== undefined) {
                localVarQueryParameter['ProductTitleSearch'] = productTitleSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCatalogApi - functional programming interface
 * @export
 */
export const ProductCatalogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductCatalogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [productCategoryId] 
         * @param {string} [productId] 
         * @param {string} [productTitleSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productCatalogExpressGet(productCategoryId?: string, productId?: string, productTitleSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productCatalogExpressGet(productCategoryId, productId, productTitleSearch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCatalogApi.productCatalogExpressGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductCatalogApi - factory interface
 * @export
 */
export const ProductCatalogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductCatalogApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [productCategoryId] 
         * @param {string} [productId] 
         * @param {string} [productTitleSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCatalogExpressGet(productCategoryId?: string, productId?: string, productTitleSearch?: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsMarketplaceExpressProductCatalogDto> {
            return localVarFp.productCatalogExpressGet(productCategoryId, productId, productTitleSearch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductCatalogApi - object-oriented interface
 * @export
 * @class ProductCatalogApi
 * @extends {BaseAPI}
 */
export class ProductCatalogApi extends BaseAPI {
    /**
     * 
     * @param {string} [productCategoryId] 
     * @param {string} [productId] 
     * @param {string} [productTitleSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCatalogApi
     */
    public productCatalogExpressGet(productCategoryId?: string, productId?: string, productTitleSearch?: string, options?: AxiosRequestConfig) {
        return ProductCatalogApiFp(this.configuration).productCatalogExpressGet(productCategoryId, productId, productTitleSearch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductCategoryApi - axios parameter creator
 * @export
 */
export const ProductCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductCategoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ProductCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductCategoryMultiLevelGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ProductCategory/MultiLevel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductCategoryTitleValueChildOnlyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ProductCategory/TitleValue/ChildOnly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductCategoryTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ProductCategory/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceProductCategoryMultiLevelGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/ProductCategory/MultiLevel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductCategoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/ProductCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductCategoryProductCategoryIdPost: async (productCategoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCategoryId' is not null or undefined
            assertParamExists('sellerProductCategoryProductCategoryIdPost', 'productCategoryId', productCategoryId)
            const localVarPath = `/Seller/ProductCategory/{productCategoryId}`
                .replace(`{${"productCategoryId"}}`, encodeURIComponent(String(productCategoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductCategoryTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/ProductCategory/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCategoryApi - functional programming interface
 * @export
 */
export const ProductCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerProductCategoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerProductCategoryGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.buyerProductCategoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerProductCategoryMultiLevelGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerProductCategoryMultiLevelGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.buyerProductCategoryMultiLevelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerProductCategoryTitleValueChildOnlyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerProductCategoryTitleValueChildOnlyGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.buyerProductCategoryTitleValueChildOnlyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerProductCategoryTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerProductCategoryTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.buyerProductCategoryTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceProductCategoryMultiLevelGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceProductCategoryMultiLevelGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.marketPlaceProductCategoryMultiLevelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerProductCategoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerProductCategoryGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.sellerProductCategoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerProductCategoryProductCategoryIdPost(productCategoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerProductCategoryProductCategoryIdPost(productCategoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.sellerProductCategoryProductCategoryIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerProductCategoryTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerProductCategoryTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoryApi.sellerProductCategoryTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductCategoryApi - factory interface
 * @export
 */
export const ProductCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductCategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductCategoryGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO>> {
            return localVarFp.buyerProductCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductCategoryMultiLevelGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO>> {
            return localVarFp.buyerProductCategoryMultiLevelGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductCategoryTitleValueChildOnlyGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.buyerProductCategoryTitleValueChildOnlyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerProductCategoryTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.buyerProductCategoryTitleValueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceProductCategoryMultiLevelGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsShopsProductCategoriesProductCategoryMultiLevelDTO>> {
            return localVarFp.marketPlaceProductCategoryMultiLevelGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductCategoryGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonQueryStringsShopsProductCategoryProductCategoriesDTO>> {
            return localVarFp.sellerProductCategoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductCategoryProductCategoryIdPost(productCategoryId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerProductCategoryProductCategoryIdPost(productCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerProductCategoryTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTO>> {
            return localVarFp.sellerProductCategoryTitleValueGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductCategoryApi - object-oriented interface
 * @export
 * @class ProductCategoryApi
 * @extends {BaseAPI}
 */
export class ProductCategoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public buyerProductCategoryGet(options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).buyerProductCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public buyerProductCategoryMultiLevelGet(options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).buyerProductCategoryMultiLevelGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public buyerProductCategoryTitleValueChildOnlyGet(options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).buyerProductCategoryTitleValueChildOnlyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public buyerProductCategoryTitleValueGet(options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).buyerProductCategoryTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public marketPlaceProductCategoryMultiLevelGet(options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).marketPlaceProductCategoryMultiLevelGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public sellerProductCategoryGet(options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).sellerProductCategoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productCategoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public sellerProductCategoryProductCategoryIdPost(productCategoryId: string, options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).sellerProductCategoryProductCategoryIdPost(productCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoryApi
     */
    public sellerProductCategoryTitleValueGet(options?: AxiosRequestConfig) {
        return ProductCategoryApiFp(this.configuration).sellerProductCategoryTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductPriceApi - axios parameter creator
 * @export
 */
export const ProductPriceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPriceCompareGet: async (productIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProductPrice/Compare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productIds) {
                localVarQueryParameter['productIds'] = productIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductPriceApi - functional programming interface
 * @export
 */
export const ProductPriceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductPriceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productPriceCompareGet(productIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsPricingProductPriceCompareDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productPriceCompareGet(productIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductPriceApi.productPriceCompareGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductPriceApi - factory interface
 * @export
 */
export const ProductPriceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductPriceApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [productIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPriceCompareGet(productIds?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsPricingProductPriceCompareDto>> {
            return localVarFp.productPriceCompareGet(productIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductPriceApi - object-oriented interface
 * @export
 * @class ProductPriceApi
 * @extends {BaseAPI}
 */
export class ProductPriceApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} [productIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPriceApi
     */
    public productPriceCompareGet(productIds?: Array<string>, options?: AxiosRequestConfig) {
        return ProductPriceApiFp(this.configuration).productPriceCompareGet(productIds, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegisterApi - axios parameter creator
 * @export
 */
export const RegisterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [token] 
         * @param {string} [returnURL] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterConfirmGet: async (id?: string, token?: string, returnURL?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register/Confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        identityRegisterPost: async (returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosRegisterUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterPut: async (identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosChangeRegisterEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterUserPost: async (returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosRegisterUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegisterApi - functional programming interface
 * @export
 */
export const RegisterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegisterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [token] 
         * @param {string} [returnURL] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityRegisterConfirmGet(id?: string, token?: string, returnURL?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterConfirmGet(id, token, returnURL, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterConfirmGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async identityRegisterPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterPost(returnURL, identityCommonDtosRegisterUserDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityRegisterUserPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterUserPost(returnURL, identityCommonDtosRegisterUserDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterUserPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RegisterApi - factory interface
 * @export
 */
export const RegisterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegisterApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [token] 
         * @param {string} [returnURL] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterConfirmGet(id?: string, token?: string, returnURL?: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterConfirmGet(id, token, returnURL, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        identityRegisterPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterPost(returnURL, identityCommonDtosRegisterUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterUserPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterUserPost(returnURL, identityCommonDtosRegisterUserDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegisterApi - object-oriented interface
 * @export
 * @class RegisterApi
 * @extends {BaseAPI}
 */
export class RegisterApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {string} [token] 
     * @param {string} [returnURL] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterConfirmGet(id?: string, token?: string, returnURL?: string, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterConfirmGet(id, token, returnURL, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterPost(returnURL, identityCommonDtosRegisterUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterUserPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterUserPost(returnURL, identityCommonDtosRegisterUserDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReviewRequestApi - axios parameter creator
 * @export
 */
export const ReviewRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [isActive] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestBuyGet: async (isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ReviewRequest/Buy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (ticketNumber !== undefined) {
                localVarQueryParameter['TicketNumber'] = ticketNumber;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestIdAcceptPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewRequestIdAcceptPut', 'id', id)
            const localVarPath = `/ReviewRequest/{id}/Accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestIdBuyGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewRequestIdBuyGet', 'id', id)
            const localVarPath = `/ReviewRequest/{id}/Buy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestIdProcessingPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewRequestIdProcessingPut', 'id', id)
            const localVarPath = `/ReviewRequest/{id}/Processing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestIdSellGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewRequestIdSellGet', 'id', id)
            const localVarPath = `/ReviewRequest/{id}/Sell`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestIdStartMediationPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reviewRequestIdStartMediationPut', 'id', id)
            const localVarPath = `/ReviewRequest/{id}/StartMediation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestSellCountGet: async (status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ReviewRequest/Sell/Count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isActive] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {boolean} [showOnlyPendings] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestSellGet: async (isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, showOnlyPendings?: boolean, ticketNumber?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ReviewRequest/Sell`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (showOnlyPendings !== undefined) {
                localVarQueryParameter['ShowOnlyPendings'] = showOnlyPendings;
            }

            if (ticketNumber !== undefined) {
                localVarQueryParameter['TicketNumber'] = ticketNumber;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewRequestApi - functional programming interface
 * @export
 */
export const ReviewRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [isActive] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewRequestBuyGet(isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewRequestBuyGet(isActive, status, ticketNumber, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewRequestApi.reviewRequestBuyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewRequestIdAcceptPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewRequestIdAcceptPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewRequestApi.reviewRequestIdAcceptPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewRequestIdBuyGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewRequestIdBuyGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewRequestApi.reviewRequestIdBuyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewRequestIdProcessingPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewRequestIdProcessingPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewRequestApi.reviewRequestIdProcessingPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewRequestIdSellGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewRequestIdSellGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewRequestApi.reviewRequestIdSellGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewRequestIdStartMediationPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewRequestIdStartMediationPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewRequestApi.reviewRequestIdStartMediationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewRequestSellCountGet(status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosSupportTicketsSupportTicketCountDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewRequestSellCountGet(status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewRequestApi.reviewRequestSellCountGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [isActive] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {boolean} [showOnlyPendings] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewRequestSellGet(isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, showOnlyPendings?: boolean, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewRequestSellGet(isActive, status, showOnlyPendings, ticketNumber, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReviewRequestApi.reviewRequestSellGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReviewRequestApi - factory interface
 * @export
 */
export const ReviewRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [isActive] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestBuyGet(isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO> {
            return localVarFp.reviewRequestBuyGet(isActive, status, ticketNumber, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestIdAcceptPut(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewRequestIdAcceptPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestIdBuyGet(id: string, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO> {
            return localVarFp.reviewRequestIdBuyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestIdProcessingPut(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewRequestIdProcessingPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestIdSellGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsOrdersOrderProductsOrderItemReportDto> {
            return localVarFp.reviewRequestIdSellGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestIdStartMediationPut(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.reviewRequestIdStartMediationPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestSellCountGet(status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosSupportTicketsSupportTicketCountDTO> {
            return localVarFp.reviewRequestSellCountGet(status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isActive] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {boolean} [showOnlyPendings] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewRequestSellGet(isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, showOnlyPendings?: boolean, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOWorkspacesCommonDtosSupportTicketsShopReportSupportTicketRowDTO> {
            return localVarFp.reviewRequestSellGet(isActive, status, showOnlyPendings, ticketNumber, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewRequestApi - object-oriented interface
 * @export
 * @class ReviewRequestApi
 * @extends {BaseAPI}
 */
export class ReviewRequestApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [isActive] 
     * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
     * @param {string} [ticketNumber] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewRequestApi
     */
    public reviewRequestBuyGet(isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ReviewRequestApiFp(this.configuration).reviewRequestBuyGet(isActive, status, ticketNumber, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewRequestApi
     */
    public reviewRequestIdAcceptPut(id: string, options?: AxiosRequestConfig) {
        return ReviewRequestApiFp(this.configuration).reviewRequestIdAcceptPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewRequestApi
     */
    public reviewRequestIdBuyGet(id: string, options?: AxiosRequestConfig) {
        return ReviewRequestApiFp(this.configuration).reviewRequestIdBuyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewRequestApi
     */
    public reviewRequestIdProcessingPut(id: string, options?: AxiosRequestConfig) {
        return ReviewRequestApiFp(this.configuration).reviewRequestIdProcessingPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewRequestApi
     */
    public reviewRequestIdSellGet(id: string, options?: AxiosRequestConfig) {
        return ReviewRequestApiFp(this.configuration).reviewRequestIdSellGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewRequestApi
     */
    public reviewRequestIdStartMediationPut(id: string, options?: AxiosRequestConfig) {
        return ReviewRequestApiFp(this.configuration).reviewRequestIdStartMediationPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewRequestApi
     */
    public reviewRequestSellCountGet(status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, options?: AxiosRequestConfig) {
        return ReviewRequestApiFp(this.configuration).reviewRequestSellCountGet(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isActive] 
     * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
     * @param {boolean} [showOnlyPendings] 
     * @param {string} [ticketNumber] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewRequestApi
     */
    public reviewRequestSellGet(isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, showOnlyPendings?: boolean, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ReviewRequestApiFp(this.configuration).reviewRequestSellGet(isActive, status, showOnlyPendings, ticketNumber, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceSearchSuggestionGet: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Search/Suggestion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceSearchSuggestionGet(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsContentSearchSearchSuggestionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceSearchSuggestionGet(query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchApi.marketPlaceSearchSuggestionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceSearchSuggestionGet(query?: string, options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsContentSearchSearchSuggestionDTO>> {
            return localVarFp.marketPlaceSearchSuggestionGet(query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public marketPlaceSearchSuggestionGet(query?: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).marketPlaceSearchSuggestionGet(query, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SecurityApi - axios parameter creator
 * @export
 */
export const SecurityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityConfirmEmailPut: async (identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Security/ConfirmEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosConfirmEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Security`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecurityApi - functional programming interface
 * @export
 */
export const SecurityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecurityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityApi.identitySecurityConfirmEmailPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identitySecurityGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identitySecurityGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityApi.identitySecurityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SecurityApi - factory interface
 * @export
 */
export const SecurityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecurityApiFp(configuration)
    return {
        /**
         * 
         * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityGet(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identitySecurityGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecurityApi - object-oriented interface
 * @export
 * @class SecurityApi
 * @extends {BaseAPI}
 */
export class SecurityApi extends BaseAPI {
    /**
     * 
     * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public identitySecurityGet(options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).identitySecurityGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShopApi - axios parameter creator
 * @export
 */
export const ShopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        buyerShopLocalCurrencyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/Shop/LocalCurrency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsUpdateShopDTO} [workspacesCommonDtosShopsUpdateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopPut: async (workspacesCommonDtosShopsUpdateShopDTO?: WorkspacesCommonDtosShopsUpdateShopDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/Shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosShopsUpdateShopDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopShopIdGet: async (shopId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('buyerShopShopIdGet', 'shopId', shopId)
            const localVarPath = `/Buyer/Shop/{shopId}`
                .replace(`{${"shopId"}}`, encodeURIComponent(String(shopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        buyerShopShopPropertiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/Shop/ShopProperties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopCurrentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Shop/Current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        marketPlaceShopShopInfoWalletIdGet: async (walletId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('marketPlaceShopShopInfoWalletIdGet', 'walletId', walletId)
            const localVarPath = `/MarketPlace/Shop/ShopInfo/{walletId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsUpdateShopDTO} [workspacesCommonDtosShopsUpdateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopPut: async (workspacesCommonDtosShopsUpdateShopDTO?: WorkspacesCommonDtosShopsUpdateShopDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/Shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosShopsUpdateShopDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopShopIdGet: async (shopId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopId' is not null or undefined
            assertParamExists('sellerShopShopIdGet', 'shopId', shopId)
            const localVarPath = `/Seller/Shop/{shopId}`
                .replace(`{${"shopId"}}`, encodeURIComponent(String(shopId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopApi - functional programming interface
 * @export
 */
export const ShopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async buyerShopLocalCurrencyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsShopsShopsLocalShopCurrencyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopLocalCurrencyGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.buyerShopLocalCurrencyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsUpdateShopDTO} [workspacesCommonDtosShopsUpdateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopPut(workspacesCommonDtosShopsUpdateShopDTO?: WorkspacesCommonDtosShopsUpdateShopDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopPut(workspacesCommonDtosShopsUpdateShopDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.buyerShopPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopShopIdGet(shopId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosShopsUpdateShopDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopShopIdGet(shopId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.buyerShopShopIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async buyerShopShopPropertiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftCommonDTOsShopsShopsShopPropertiesDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopShopPropertiesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.buyerShopShopPropertiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceShopCurrentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosShopsUpdateShopDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceShopCurrentGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.marketPlaceShopCurrentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async marketPlaceShopShopInfoWalletIdGet(walletId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceShopShopInfoWalletIdGet(walletId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.marketPlaceShopShopInfoWalletIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsUpdateShopDTO} [workspacesCommonDtosShopsUpdateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerShopPut(workspacesCommonDtosShopsUpdateShopDTO?: WorkspacesCommonDtosShopsUpdateShopDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerShopPut(workspacesCommonDtosShopsUpdateShopDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.sellerShopPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerShopShopIdGet(shopId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosShopsUpdateShopDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerShopShopIdGet(shopId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopApi.sellerShopShopIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShopApi - factory interface
 * @export
 */
export const ShopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        buyerShopLocalCurrencyGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsShopsShopsLocalShopCurrencyDTO> {
            return localVarFp.buyerShopLocalCurrencyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsUpdateShopDTO} [workspacesCommonDtosShopsUpdateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopPut(workspacesCommonDtosShopsUpdateShopDTO?: WorkspacesCommonDtosShopsUpdateShopDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerShopPut(workspacesCommonDtosShopsUpdateShopDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopShopIdGet(shopId: string, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosShopsUpdateShopDTO> {
            return localVarFp.buyerShopShopIdGet(shopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        buyerShopShopPropertiesGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftCommonDTOsShopsShopsShopPropertiesDTO>> {
            return localVarFp.buyerShopShopPropertiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopCurrentGet(options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosShopsUpdateShopDTO> {
            return localVarFp.marketPlaceShopCurrentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        marketPlaceShopShopInfoWalletIdGet(walletId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.marketPlaceShopShopInfoWalletIdGet(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsUpdateShopDTO} [workspacesCommonDtosShopsUpdateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopPut(workspacesCommonDtosShopsUpdateShopDTO?: WorkspacesCommonDtosShopsUpdateShopDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerShopPut(workspacesCommonDtosShopsUpdateShopDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shopId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopShopIdGet(shopId: string, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosShopsUpdateShopDTO> {
            return localVarFp.sellerShopShopIdGet(shopId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopApi - object-oriented interface
 * @export
 * @class ShopApi
 * @extends {BaseAPI}
 */
export class ShopApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public buyerShopLocalCurrencyGet(options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).buyerShopLocalCurrencyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosShopsUpdateShopDTO} [workspacesCommonDtosShopsUpdateShopDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public buyerShopPut(workspacesCommonDtosShopsUpdateShopDTO?: WorkspacesCommonDtosShopsUpdateShopDTO, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).buyerShopPut(workspacesCommonDtosShopsUpdateShopDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shopId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public buyerShopShopIdGet(shopId: string, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).buyerShopShopIdGet(shopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public buyerShopShopPropertiesGet(options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).buyerShopShopPropertiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public marketPlaceShopCurrentGet(options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).marketPlaceShopCurrentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public marketPlaceShopShopInfoWalletIdGet(walletId: string, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).marketPlaceShopShopInfoWalletIdGet(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosShopsUpdateShopDTO} [workspacesCommonDtosShopsUpdateShopDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public sellerShopPut(workspacesCommonDtosShopsUpdateShopDTO?: WorkspacesCommonDtosShopsUpdateShopDTO, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).sellerShopPut(workspacesCommonDtosShopsUpdateShopDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shopId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public sellerShopShopIdGet(shopId: string, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).sellerShopShopIdGet(shopId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShopConfigApi - axios parameter creator
 * @export
 */
export const ShopConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopConfigFullInformationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ShopConfig/FullInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ShopConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopConfigPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ShopConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO} [apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopConfigPut: async (apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO?: ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ShopConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopConfigSecretKeyGeneratorPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ShopConfig/SecretKey/Generator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopConfigApi - functional programming interface
 * @export
 */
export const ShopConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopConfigFullInformationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopConfigFullInformationGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopConfigApi.buyerShopConfigFullInformationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopConfigGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopConfigGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopConfigApi.buyerShopConfigGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopConfigPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopConfigPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopConfigApi.buyerShopConfigPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO} [apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopConfigPut(apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO?: ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsShopsConfigSetShopApiConfigResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopConfigPut(apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopConfigApi.buyerShopConfigPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopConfigSecretKeyGeneratorPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopConfigSecretKeyGeneratorPut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopConfigApi.buyerShopConfigSecretKeyGeneratorPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShopConfigApi - factory interface
 * @export
 */
export const ShopConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopConfigFullInformationGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO> {
            return localVarFp.buyerShopConfigFullInformationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopConfigGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsShopsConfigGetShopApiConfigDTO> {
            return localVarFp.buyerShopConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopConfigPost(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerShopConfigPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO} [apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopConfigPut(apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO?: ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsShopsConfigSetShopApiConfigResponseDTO> {
            return localVarFp.buyerShopConfigPut(apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopConfigSecretKeyGeneratorPut(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerShopConfigSecretKeyGeneratorPut(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopConfigApi - object-oriented interface
 * @export
 * @class ShopConfigApi
 * @extends {BaseAPI}
 */
export class ShopConfigApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopConfigApi
     */
    public buyerShopConfigFullInformationGet(options?: AxiosRequestConfig) {
        return ShopConfigApiFp(this.configuration).buyerShopConfigFullInformationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopConfigApi
     */
    public buyerShopConfigGet(options?: AxiosRequestConfig) {
        return ShopConfigApiFp(this.configuration).buyerShopConfigGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopConfigApi
     */
    public buyerShopConfigPost(options?: AxiosRequestConfig) {
        return ShopConfigApiFp(this.configuration).buyerShopConfigPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO} [apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopConfigApi
     */
    public buyerShopConfigPut(apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO?: ApiGiftCommonDTOsShopsConfigSetShopApiConfigDTO, options?: AxiosRequestConfig) {
        return ShopConfigApiFp(this.configuration).buyerShopConfigPut(apiGiftCommonDTOsShopsConfigSetShopApiConfigDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopConfigApi
     */
    public buyerShopConfigSecretKeyGeneratorPut(options?: AxiosRequestConfig) {
        return ShopConfigApiFp(this.configuration).buyerShopConfigSecretKeyGeneratorPut(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShopMemberApi - axios parameter creator
 * @export
 */
export const ShopMemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [returnUrl] 
         * @param {boolean} [isAccepted] 
         * @param {string} [requestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopMemberConfirmGet: async (token?: string, returnUrl?: string, isAccepted?: boolean, requestId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ShopMember/Confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['ReturnUrl'] = returnUrl;
            }

            if (isAccepted !== undefined) {
                localVarQueryParameter['IsAccepted'] = isAccepted;
            }

            if (requestId !== undefined) {
                localVarQueryParameter['RequestId'] = requestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [onlyPendings] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopMemberGet: async (onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ShopMember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (onlyPendings !== undefined) {
                localVarQueryParameter['OnlyPendings'] = onlyPendings;
            }

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO} [workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopMemberPost: async (workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO?: WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ShopMember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shopMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopMemberShopMemberIdDelete: async (shopMemberId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopMemberId' is not null or undefined
            assertParamExists('buyerShopMemberShopMemberIdDelete', 'shopMemberId', shopMemberId)
            const localVarPath = `/Buyer/ShopMember/{shopMemberId}`
                .replace(`{${"shopMemberId"}}`, encodeURIComponent(String(shopMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [returnUrl] 
         * @param {boolean} [isAccepted] 
         * @param {string} [requestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopMemberConfirmGet: async (token?: string, returnUrl?: string, isAccepted?: boolean, requestId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/ShopMember/Confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['ReturnUrl'] = returnUrl;
            }

            if (isAccepted !== undefined) {
                localVarQueryParameter['IsAccepted'] = isAccepted;
            }

            if (requestId !== undefined) {
                localVarQueryParameter['RequestId'] = requestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [onlyPendings] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopMemberGet: async (onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/ShopMember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (onlyPendings !== undefined) {
                localVarQueryParameter['OnlyPendings'] = onlyPendings;
            }

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO} [workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopMemberPost: async (workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO?: WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/ShopMember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} shopMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopMemberShopMemberIdDelete: async (shopMemberId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopMemberId' is not null or undefined
            assertParamExists('sellerShopMemberShopMemberIdDelete', 'shopMemberId', shopMemberId)
            const localVarPath = `/Seller/ShopMember/{shopMemberId}`
                .replace(`{${"shopMemberId"}}`, encodeURIComponent(String(shopMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopMemberApi - functional programming interface
 * @export
 */
export const ShopMemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopMemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [returnUrl] 
         * @param {boolean} [isAccepted] 
         * @param {string} [requestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopMemberConfirmGet(token?: string, returnUrl?: string, isAccepted?: boolean, requestId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopMemberConfirmGet(token, returnUrl, isAccepted, requestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopMemberApi.buyerShopMemberConfirmGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [onlyPendings] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopMemberGet(onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopMemberGet(onlyPendings, email, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopMemberApi.buyerShopMemberGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO} [workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO?: WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopMemberApi.buyerShopMemberPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} shopMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopMemberShopMemberIdDelete(shopMemberId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopMemberShopMemberIdDelete(shopMemberId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopMemberApi.buyerShopMemberShopMemberIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [returnUrl] 
         * @param {boolean} [isAccepted] 
         * @param {string} [requestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerShopMemberConfirmGet(token?: string, returnUrl?: string, isAccepted?: boolean, requestId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerShopMemberConfirmGet(token, returnUrl, isAccepted, requestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopMemberApi.sellerShopMemberConfirmGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [onlyPendings] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerShopMemberGet(onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerShopMemberGet(onlyPendings, email, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopMemberApi.sellerShopMemberGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO} [workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO?: WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopMemberApi.sellerShopMemberPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} shopMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerShopMemberShopMemberIdDelete(shopMemberId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerShopMemberShopMemberIdDelete(shopMemberId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopMemberApi.sellerShopMemberShopMemberIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShopMemberApi - factory interface
 * @export
 */
export const ShopMemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopMemberApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [returnUrl] 
         * @param {boolean} [isAccepted] 
         * @param {string} [requestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopMemberConfirmGet(token?: string, returnUrl?: string, isAccepted?: boolean, requestId?: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerShopMemberConfirmGet(token, returnUrl, isAccepted, requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [onlyPendings] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopMemberGet(onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberRowDTO> {
            return localVarFp.buyerShopMemberGet(onlyPendings, email, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO} [workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO?: WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.buyerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shopMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopMemberShopMemberIdDelete(shopMemberId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerShopMemberShopMemberIdDelete(shopMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [returnUrl] 
         * @param {boolean} [isAccepted] 
         * @param {string} [requestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopMemberConfirmGet(token?: string, returnUrl?: string, isAccepted?: boolean, requestId?: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerShopMemberConfirmGet(token, returnUrl, isAccepted, requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [onlyPendings] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopMemberGet(onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberRowDTO> {
            return localVarFp.sellerShopMemberGet(onlyPendings, email, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO} [workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO?: WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.sellerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} shopMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopMemberShopMemberIdDelete(shopMemberId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sellerShopMemberShopMemberIdDelete(shopMemberId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopMemberApi - object-oriented interface
 * @export
 * @class ShopMemberApi
 * @extends {BaseAPI}
 */
export class ShopMemberApi extends BaseAPI {
    /**
     * 
     * @param {string} [token] 
     * @param {string} [returnUrl] 
     * @param {boolean} [isAccepted] 
     * @param {string} [requestId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopMemberApi
     */
    public buyerShopMemberConfirmGet(token?: string, returnUrl?: string, isAccepted?: boolean, requestId?: string, options?: AxiosRequestConfig) {
        return ShopMemberApiFp(this.configuration).buyerShopMemberConfirmGet(token, returnUrl, isAccepted, requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [onlyPendings] 
     * @param {string} [email] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopMemberApi
     */
    public buyerShopMemberGet(onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ShopMemberApiFp(this.configuration).buyerShopMemberGet(onlyPendings, email, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO} [workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopMemberApi
     */
    public buyerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO?: WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options?: AxiosRequestConfig) {
        return ShopMemberApiFp(this.configuration).buyerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shopMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopMemberApi
     */
    public buyerShopMemberShopMemberIdDelete(shopMemberId: string, options?: AxiosRequestConfig) {
        return ShopMemberApiFp(this.configuration).buyerShopMemberShopMemberIdDelete(shopMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [token] 
     * @param {string} [returnUrl] 
     * @param {boolean} [isAccepted] 
     * @param {string} [requestId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopMemberApi
     */
    public sellerShopMemberConfirmGet(token?: string, returnUrl?: string, isAccepted?: boolean, requestId?: string, options?: AxiosRequestConfig) {
        return ShopMemberApiFp(this.configuration).sellerShopMemberConfirmGet(token, returnUrl, isAccepted, requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [onlyPendings] 
     * @param {string} [email] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopMemberApi
     */
    public sellerShopMemberGet(onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ShopMemberApiFp(this.configuration).sellerShopMemberGet(onlyPendings, email, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO} [workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopMemberApi
     */
    public sellerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO?: WorkspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options?: AxiosRequestConfig) {
        return ShopMemberApiFp(this.configuration).sellerShopMemberPost(workspacesCommonDtosShopMembersShopMemberInvitationNotificationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} shopMemberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopMemberApi
     */
    public sellerShopMemberShopMemberIdDelete(shopMemberId: string, options?: AxiosRequestConfig) {
        return ShopMemberApiFp(this.configuration).sellerShopMemberShopMemberIdDelete(shopMemberId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShopMemberInviteApi - axios parameter creator
 * @export
 */
export const ShopMemberInviteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [onlyPendings] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopMemberInviteGet: async (onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/ShopMemberInvite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (onlyPendings !== undefined) {
                localVarQueryParameter['OnlyPendings'] = onlyPendings;
            }

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopMemberInviteRevokeIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketPlaceShopMemberInviteRevokeIdDelete', 'id', id)
            const localVarPath = `/MarketPlace/ShopMemberInvite/Revoke/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopMemberInviteApi - functional programming interface
 * @export
 */
export const ShopMemberInviteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopMemberInviteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [onlyPendings] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceShopMemberInviteGet(onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberInvitationRowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceShopMemberInviteGet(onlyPendings, email, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopMemberInviteApi.marketPlaceShopMemberInviteGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceShopMemberInviteRevokeIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceShopMemberInviteRevokeIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopMemberInviteApi.marketPlaceShopMemberInviteRevokeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShopMemberInviteApi - factory interface
 * @export
 */
export const ShopMemberInviteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopMemberInviteApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [onlyPendings] 
         * @param {string} [email] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopMemberInviteGet(onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopMembersShopMemberInvitationRowDto> {
            return localVarFp.marketPlaceShopMemberInviteGet(onlyPendings, email, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopMemberInviteRevokeIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.marketPlaceShopMemberInviteRevokeIdDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopMemberInviteApi - object-oriented interface
 * @export
 * @class ShopMemberInviteApi
 * @extends {BaseAPI}
 */
export class ShopMemberInviteApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [onlyPendings] 
     * @param {string} [email] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopMemberInviteApi
     */
    public marketPlaceShopMemberInviteGet(onlyPendings?: boolean, email?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ShopMemberInviteApiFp(this.configuration).marketPlaceShopMemberInviteGet(onlyPendings, email, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopMemberInviteApi
     */
    public marketPlaceShopMemberInviteRevokeIdDelete(id: string, options?: AxiosRequestConfig) {
        return ShopMemberInviteApiFp(this.configuration).marketPlaceShopMemberInviteRevokeIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShopPolicyApi - axios parameter creator
 * @export
 */
export const ShopPolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsPoliciesPackageStatus} [status] 
         * @param {string} [shopId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopPolicyGet: async (status?: WorkspacesCommonEnumsPoliciesPackageStatus, shopId?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/ShopPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['ShopId'] = shopId;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopPolicyPackageKeyPost: async (packageKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageKey' is not null or undefined
            assertParamExists('buyerShopPolicyPackageKeyPost', 'packageKey', packageKey)
            const localVarPath = `/Buyer/ShopPolicy/{packageKey}`
                .replace(`{${"packageKey"}}`, encodeURIComponent(String(packageKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopPolicyStatusPackageKeyGet: async (packageKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageKey' is not null or undefined
            assertParamExists('buyerShopPolicyStatusPackageKeyGet', 'packageKey', packageKey)
            const localVarPath = `/Buyer/ShopPolicy/Status/{packageKey}`
                .replace(`{${"packageKey"}}`, encodeURIComponent(String(packageKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopPolicyApi - functional programming interface
 * @export
 */
export const ShopPolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopPolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsPoliciesPackageStatus} [status] 
         * @param {string} [shopId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopPolicyGet(status?: WorkspacesCommonEnumsPoliciesPackageStatus, shopId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopPolicyGet(status, shopId, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopPolicyApi.buyerShopPolicyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} packageKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopPolicyPackageKeyPost(packageKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopPolicyPackageKeyPost(packageKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopPolicyApi.buyerShopPolicyPackageKeyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} packageKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopPolicyStatusPackageKeyGet(packageKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftCommonDTOsShopsShopsGetShopPolicyDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopPolicyStatusPackageKeyGet(packageKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopPolicyApi.buyerShopPolicyStatusPackageKeyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShopPolicyApi - factory interface
 * @export
 */
export const ShopPolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopPolicyApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsPoliciesPackageStatus} [status] 
         * @param {string} [shopId] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopPolicyGet(status?: WorkspacesCommonEnumsPoliciesPackageStatus, shopId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsShopsShopsGetPagedShopPackageDTO> {
            return localVarFp.buyerShopPolicyGet(status, shopId, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopPolicyPackageKeyPost(packageKey: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.buyerShopPolicyPackageKeyPost(packageKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopPolicyStatusPackageKeyGet(packageKey: string, options?: AxiosRequestConfig): AxiosPromise<ApiGiftCommonDTOsShopsShopsGetShopPolicyDTO> {
            return localVarFp.buyerShopPolicyStatusPackageKeyGet(packageKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopPolicyApi - object-oriented interface
 * @export
 * @class ShopPolicyApi
 * @extends {BaseAPI}
 */
export class ShopPolicyApi extends BaseAPI {
    /**
     * 
     * @param {WorkspacesCommonEnumsPoliciesPackageStatus} [status] 
     * @param {string} [shopId] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopPolicyApi
     */
    public buyerShopPolicyGet(status?: WorkspacesCommonEnumsPoliciesPackageStatus, shopId?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ShopPolicyApiFp(this.configuration).buyerShopPolicyGet(status, shopId, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopPolicyApi
     */
    public buyerShopPolicyPackageKeyPost(packageKey: string, options?: AxiosRequestConfig) {
        return ShopPolicyApiFp(this.configuration).buyerShopPolicyPackageKeyPost(packageKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopPolicyApi
     */
    public buyerShopPolicyStatusPackageKeyGet(packageKey: string, options?: AxiosRequestConfig) {
        return ShopPolicyApiFp(this.configuration).buyerShopPolicyStatusPackageKeyGet(packageKey, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShopsApi - axios parameter creator
 * @export
 */
export const ShopsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopGet: async (status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/Shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopPost: async (workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Buyer/Shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosShopsCreateShopDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopGet: async (status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopOverviewGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Shop/Overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopPost: async (workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosShopsCreateShopDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopTitleValueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Shop/TitleValue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopGet: async (status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/Shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopPost: async (workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Seller/Shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosShopsCreateShopDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopsApi - functional programming interface
 * @export
 */
export const ShopsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopGet(status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopsApi.buyerShopGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerShopPost(workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerShopPost(workspacesCommonDtosShopsCreateShopDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopsApi.buyerShopPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceShopGet(status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopsApi.marketPlaceShopGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceShopOverviewGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonQueriesShopsGetShopOverviewQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceShopOverviewGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopsApi.marketPlaceShopOverviewGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceShopPost(workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceShopPost(workspacesCommonDtosShopsCreateShopDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopsApi.marketPlaceShopPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceShopTitleValueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceShopTitleValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopsApi.marketPlaceShopTitleValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerShopGet(status, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopsApi.sellerShopGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sellerShopPost(workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sellerShopPost(workspacesCommonDtosShopsCreateShopDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShopsApi.sellerShopPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShopsApi - factory interface
 * @export
 */
export const ShopsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopsApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO> {
            return localVarFp.buyerShopGet(status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerShopPost(workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.buyerShopPost(workspacesCommonDtosShopsCreateShopDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO> {
            return localVarFp.marketPlaceShopGet(status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopOverviewGet(options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonQueriesShopsGetShopOverviewQueryResponse> {
            return localVarFp.marketPlaceShopOverviewGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopPost(workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.marketPlaceShopPost(workspacesCommonDtosShopsCreateShopDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceShopTitleValueGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApiGiftGatewayCommonDTOsBaseTitleValueDTOSystemGuid>> {
            return localVarFp.marketPlaceShopTitleValueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOWorkspacesCommonDtosShopsShopRowDTO> {
            return localVarFp.sellerShopGet(status, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sellerShopPost(workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.sellerShopPost(workspacesCommonDtosShopsCreateShopDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopsApi - object-oriented interface
 * @export
 * @class ShopsApi
 * @extends {BaseAPI}
 */
export class ShopsApi extends BaseAPI {
    /**
     * 
     * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public buyerShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ShopsApiFp(this.configuration).buyerShopGet(status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public buyerShopPost(workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options?: AxiosRequestConfig) {
        return ShopsApiFp(this.configuration).buyerShopPost(workspacesCommonDtosShopsCreateShopDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public marketPlaceShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ShopsApiFp(this.configuration).marketPlaceShopGet(status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public marketPlaceShopOverviewGet(options?: AxiosRequestConfig) {
        return ShopsApiFp(this.configuration).marketPlaceShopOverviewGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public marketPlaceShopPost(workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options?: AxiosRequestConfig) {
        return ShopsApiFp(this.configuration).marketPlaceShopPost(workspacesCommonDtosShopsCreateShopDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public marketPlaceShopTitleValueGet(options?: AxiosRequestConfig) {
        return ShopsApiFp(this.configuration).marketPlaceShopTitleValueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonEnumsShopsShopStatus} [status] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public sellerShopGet(status?: WorkspacesCommonEnumsShopsShopStatus, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ShopsApiFp(this.configuration).sellerShopGet(status, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosShopsCreateShopDTO} [workspacesCommonDtosShopsCreateShopDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopsApi
     */
    public sellerShopPost(workspacesCommonDtosShopsCreateShopDTO?: WorkspacesCommonDtosShopsCreateShopDTO, options?: AxiosRequestConfig) {
        return ShopsApiFp(this.configuration).sellerShopPost(workspacesCommonDtosShopsCreateShopDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SocialNetworkApi - axios parameter creator
 * @export
 */
export const SocialNetworkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySocialNetworkGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/SocialNetwork`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [message] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySocialNetworkPost: async (message?: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/SocialNetwork`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SocialNetworkApi - functional programming interface
 * @export
 */
export const SocialNetworkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SocialNetworkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identitySocialNetworkGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identitySocialNetworkGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SocialNetworkApi.identitySocialNetworkGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [message] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identitySocialNetworkPost(message?: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identitySocialNetworkPost(message, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SocialNetworkApi.identitySocialNetworkPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SocialNetworkApi - factory interface
 * @export
 */
export const SocialNetworkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SocialNetworkApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySocialNetworkGet(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.identitySocialNetworkGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [message] 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySocialNetworkPost(message?: string, body?: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identitySocialNetworkPost(message, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SocialNetworkApi - object-oriented interface
 * @export
 * @class SocialNetworkApi
 * @extends {BaseAPI}
 */
export class SocialNetworkApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialNetworkApi
     */
    public identitySocialNetworkGet(options?: AxiosRequestConfig) {
        return SocialNetworkApiFp(this.configuration).identitySocialNetworkGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [message] 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialNetworkApi
     */
    public identitySocialNetworkPost(message?: string, body?: string, options?: AxiosRequestConfig) {
        return SocialNetworkApiFp(this.configuration).identitySocialNetworkPost(message, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SupportTicketTagApi - axios parameter creator
 * @export
 */
export const SupportTicketTagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketTagIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('supportTicketTagIdDelete', 'id', id)
            const localVarPath = `/SupportTicketTag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketTagPost: async (workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SupportTicketTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportTicketTagApi - functional programming interface
 * @export
 */
export const SupportTicketTagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportTicketTagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportTicketTagIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportTicketTagIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketTagApi.supportTicketTagIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportTicketTagPost(workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportTicketTagPost(workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketTagApi.supportTicketTagPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupportTicketTagApi - factory interface
 * @export
 */
export const SupportTicketTagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportTicketTagApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketTagIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.supportTicketTagIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketTagPost(workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.supportTicketTagPost(workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportTicketTagApi - object-oriented interface
 * @export
 * @class SupportTicketTagApi
 * @extends {BaseAPI}
 */
export class SupportTicketTagApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketTagApi
     */
    public supportTicketTagIdDelete(id: string, options?: AxiosRequestConfig) {
        return SupportTicketTagApiFp(this.configuration).supportTicketTagIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketTagApi
     */
    public supportTicketTagPost(workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO, options?: AxiosRequestConfig) {
        return SupportTicketTagApiFp(this.configuration).supportTicketTagPost(workspacesCommonDtosSupportTicketsCreateSupportTicketTagDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SupportTicketsApi - axios parameter creator
 * @export
 */
export const SupportTicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsDepartmentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SupportTickets/Departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isActive] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsGet: async (isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SupportTickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['IsActive'] = isActive;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (ticketNumber !== undefined) {
                localVarQueryParameter['TicketNumber'] = ticketNumber;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsPost: async (workspacesCommonDtosSupportTicketsCreateSupportTicketDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SupportTickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsCreateSupportTicketDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} supportTicketId 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsSupportTicketIdCommentPost: async (supportTicketId: string, workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportTicketId' is not null or undefined
            assertParamExists('supportTicketsSupportTicketIdCommentPost', 'supportTicketId', supportTicketId)
            const localVarPath = `/SupportTickets/{supportTicketId}/Comment`
                .replace(`{${"supportTicketId"}}`, encodeURIComponent(String(supportTicketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} supportTicketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsSupportTicketIdGet: async (supportTicketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportTicketId' is not null or undefined
            assertParamExists('supportTicketsSupportTicketIdGet', 'supportTicketId', supportTicketId)
            const localVarPath = `/SupportTickets/{supportTicketId}`
                .replace(`{${"supportTicketId"}}`, encodeURIComponent(String(supportTicketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} supportTicketId 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsSupportTicketIdPut: async (supportTicketId: string, workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supportTicketId' is not null or undefined
            assertParamExists('supportTicketsSupportTicketIdPut', 'supportTicketId', supportTicketId)
            const localVarPath = `/SupportTickets/{supportTicketId}`
                .replace(`{${"supportTicketId"}}`, encodeURIComponent(String(supportTicketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO} [workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsVotePut: async (workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO?: WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SupportTickets/Vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportTicketsApi - functional programming interface
 * @export
 */
export const SupportTicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportTicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportTicketsDepartmentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspacesCommonDtosBaseTitleValueDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportTicketsDepartmentsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketsApi.supportTicketsDepartmentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [isActive] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportTicketsGet(isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportTicketsGet(isActive, status, ticketNumber, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketsApi.supportTicketsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportTicketsPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportTicketsPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketsApi.supportTicketsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} supportTicketId 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportTicketsSupportTicketIdCommentPost(supportTicketId: string, workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportTicketsSupportTicketIdCommentPost(supportTicketId, workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketsApi.supportTicketsSupportTicketIdCommentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} supportTicketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportTicketsSupportTicketIdGet(supportTicketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportTicketsSupportTicketIdGet(supportTicketId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketsApi.supportTicketsSupportTicketIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} supportTicketId 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportTicketsSupportTicketIdPut(supportTicketId: string, workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportTicketsSupportTicketIdPut(supportTicketId, workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketsApi.supportTicketsSupportTicketIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO} [workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportTicketsVotePut(workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO?: WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportTicketsVotePut(workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SupportTicketsApi.supportTicketsVotePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SupportTicketsApi - factory interface
 * @export
 */
export const SupportTicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportTicketsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsDepartmentsGet(options?: AxiosRequestConfig): AxiosPromise<Array<WorkspacesCommonDtosBaseTitleValueDTO>> {
            return localVarFp.supportTicketsDepartmentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isActive] 
         * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
         * @param {string} [ticketNumber] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsGet(isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosBaseTableDTOApiGiftCommonDTOsMainManagementSupportTicketsSupportTicketRowDTO> {
            return localVarFp.supportTicketsGet(isActive, status, ticketNumber, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.supportTicketsPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} supportTicketId 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsSupportTicketIdCommentPost(supportTicketId: string, workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.supportTicketsSupportTicketIdCommentPost(supportTicketId, workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} supportTicketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsSupportTicketIdGet(supportTicketId: string, options?: AxiosRequestConfig): AxiosPromise<WorkspacesCommonDtosSupportTicketsShopSupportTicketDetailsDTO> {
            return localVarFp.supportTicketsSupportTicketIdGet(supportTicketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} supportTicketId 
         * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsSupportTicketIdPut(supportTicketId: string, workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.supportTicketsSupportTicketIdPut(supportTicketId, workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO} [workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportTicketsVotePut(workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO?: WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.supportTicketsVotePut(workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportTicketsApi - object-oriented interface
 * @export
 * @class SupportTicketsApi
 * @extends {BaseAPI}
 */
export class SupportTicketsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketsApi
     */
    public supportTicketsDepartmentsGet(options?: AxiosRequestConfig) {
        return SupportTicketsApiFp(this.configuration).supportTicketsDepartmentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isActive] 
     * @param {WorkspacesCommonEnumsSupportTicketsSupportTicketStatus} [status] 
     * @param {string} [ticketNumber] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketsApi
     */
    public supportTicketsGet(isActive?: boolean, status?: WorkspacesCommonEnumsSupportTicketsSupportTicketStatus, ticketNumber?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return SupportTicketsApiFp(this.configuration).supportTicketsGet(isActive, status, ticketNumber, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketsApi
     */
    public supportTicketsPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketDTO, options?: AxiosRequestConfig) {
        return SupportTicketsApiFp(this.configuration).supportTicketsPost(workspacesCommonDtosSupportTicketsCreateSupportTicketDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} supportTicketId 
     * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketsApi
     */
    public supportTicketsSupportTicketIdCommentPost(supportTicketId: string, workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options?: AxiosRequestConfig) {
        return SupportTicketsApiFp(this.configuration).supportTicketsSupportTicketIdCommentPost(supportTicketId, workspacesCommonDtosSupportTicketsCreateSupportTicketCommentDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} supportTicketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketsApi
     */
    public supportTicketsSupportTicketIdGet(supportTicketId: string, options?: AxiosRequestConfig) {
        return SupportTicketsApiFp(this.configuration).supportTicketsSupportTicketIdGet(supportTicketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} supportTicketId 
     * @param {WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO} [workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketsApi
     */
    public supportTicketsSupportTicketIdPut(supportTicketId: string, workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO?: WorkspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO, options?: AxiosRequestConfig) {
        return SupportTicketsApiFp(this.configuration).supportTicketsSupportTicketIdPut(supportTicketId, workspacesCommonDtosSupportTicketsCreateSupportTicketReplyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO} [workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportTicketsApi
     */
    public supportTicketsVotePut(workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO?: WorkspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO, options?: AxiosRequestConfig) {
        return SupportTicketsApiFp(this.configuration).supportTicketsVotePut(workspacesCommonDtosSupportTicketsUpdateSupportTicketVoteDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TakeoutInventoryApi - axios parameter creator
 * @export
 */
export const TakeoutInventoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [sourceKey] 
         * @param {string} [sourceDeliveryKey] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceTakeoutInventoryGet: async (sourceKey?: string, sourceDeliveryKey?: string, pageSkip?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/TakeoutInventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sourceKey !== undefined) {
                localVarQueryParameter['SourceKey'] = sourceKey;
            }

            if (sourceDeliveryKey !== undefined) {
                localVarQueryParameter['SourceDeliveryKey'] = sourceDeliveryKey;
            }

            if (pageSkip !== undefined) {
                localVarQueryParameter['PageSkip'] = pageSkip;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto} [apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceTakeoutInventoryPost: async (apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto?: ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/TakeoutInventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceTakeoutInventoryResendWebhookIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketPlaceTakeoutInventoryResendWebhookIdPut', 'id', id)
            const localVarPath = `/MarketPlace/TakeoutInventory/ResendWebhook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deliveryKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryGet: async (deliveryKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryKey' is not null or undefined
            assertParamExists('v2TakeoutInventoryGet', 'deliveryKey', deliveryKey)
            const localVarPath = `/v2/TakeoutInventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deliveryKey !== undefined) {
                localVarQueryParameter['deliveryKey'] = deliveryKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto} [apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryPost: async (apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto?: ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/TakeoutInventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} takeoutInventoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryTakeoutInventoryIdDelete: async (takeoutInventoryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoutInventoryId' is not null or undefined
            assertParamExists('v2TakeoutInventoryTakeoutInventoryIdDelete', 'takeoutInventoryId', takeoutInventoryId)
            const localVarPath = `/v2/TakeoutInventory/{takeoutInventoryId}`
                .replace(`{${"takeoutInventoryId"}}`, encodeURIComponent(String(takeoutInventoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TakeoutInventoryApi - functional programming interface
 * @export
 */
export const TakeoutInventoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TakeoutInventoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [sourceKey] 
         * @param {string} [sourceDeliveryKey] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceTakeoutInventoryGet(sourceKey?: string, sourceDeliveryKey?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceTakeoutInventoryGet(sourceKey, sourceDeliveryKey, pageSkip, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TakeoutInventoryApi.marketPlaceTakeoutInventoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto} [apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceTakeoutInventoryPost(apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto?: ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceTakeoutInventoryPost(apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TakeoutInventoryApi.marketPlaceTakeoutInventoryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketPlaceTakeoutInventoryResendWebhookIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceTakeoutInventoryResendWebhookIdPut(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TakeoutInventoryApi.marketPlaceTakeoutInventoryResendWebhookIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} deliveryKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TakeoutInventoryGet(deliveryKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2TakeoutInventoryGet(deliveryKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TakeoutInventoryApi.v2TakeoutInventoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto} [apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto?: ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TakeoutInventoryApi.v2TakeoutInventoryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} takeoutInventoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TakeoutInventoryApi.v2TakeoutInventoryTakeoutInventoryIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TakeoutInventoryApi - factory interface
 * @export
 */
export const TakeoutInventoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TakeoutInventoryApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [sourceKey] 
         * @param {string} [sourceDeliveryKey] 
         * @param {number} [pageSkip] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceTakeoutInventoryGet(sourceKey?: string, sourceDeliveryKey?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsBaseTableDTOApiGiftCommonDTOsInventoryInventoryTakeoutInventoryTakeoutInventoryRowDTO> {
            return localVarFp.marketPlaceTakeoutInventoryGet(sourceKey, sourceDeliveryKey, pageSkip, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto} [apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceTakeoutInventoryPost(apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto?: ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.marketPlaceTakeoutInventoryPost(apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketPlaceTakeoutInventoryResendWebhookIdPut(id: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.marketPlaceTakeoutInventoryResendWebhookIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deliveryKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryGet(deliveryKey: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v2TakeoutInventoryGet(deliveryKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto} [apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto?: ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} takeoutInventoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TakeoutInventoryApi - object-oriented interface
 * @export
 * @class TakeoutInventoryApi
 * @extends {BaseAPI}
 */
export class TakeoutInventoryApi extends BaseAPI {
    /**
     * 
     * @param {string} [sourceKey] 
     * @param {string} [sourceDeliveryKey] 
     * @param {number} [pageSkip] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoutInventoryApi
     */
    public marketPlaceTakeoutInventoryGet(sourceKey?: string, sourceDeliveryKey?: string, pageSkip?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return TakeoutInventoryApiFp(this.configuration).marketPlaceTakeoutInventoryGet(sourceKey, sourceDeliveryKey, pageSkip, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto} [apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoutInventoryApi
     */
    public marketPlaceTakeoutInventoryPost(apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto?: ApiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto, options?: AxiosRequestConfig) {
        return TakeoutInventoryApiFp(this.configuration).marketPlaceTakeoutInventoryPost(apiGiftCommonDTOsInventoryInventoryTakeoutInventoryCreateManualTakeoutInventoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoutInventoryApi
     */
    public marketPlaceTakeoutInventoryResendWebhookIdPut(id: string, options?: AxiosRequestConfig) {
        return TakeoutInventoryApiFp(this.configuration).marketPlaceTakeoutInventoryResendWebhookIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deliveryKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoutInventoryApi
     */
    public v2TakeoutInventoryGet(deliveryKey: string, options?: AxiosRequestConfig) {
        return TakeoutInventoryApiFp(this.configuration).v2TakeoutInventoryGet(deliveryKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto} [apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoutInventoryApi
     */
    public v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto?: ApiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options?: AxiosRequestConfig) {
        return TakeoutInventoryApiFp(this.configuration).v2TakeoutInventoryPost(apiGiftCommonDTOsOrdersOrdersAPIV2CreateTakeoutInventoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} takeoutInventoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoutInventoryApi
     */
    public v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId: string, options?: AxiosRequestConfig) {
        return TakeoutInventoryApiFp(this.configuration).v2TakeoutInventoryTakeoutInventoryIdDelete(takeoutInventoryId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TwoFactorApi - axios parameter creator
 * @export
 */
export const TwoFactorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorDisablePut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactor/Disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorEnablePut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactor/Enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwoFactorApi - functional programming interface
 * @export
 */
export const TwoFactorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwoFactorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorDisablePut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorDisablePut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.identityTwoFactorDisablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorEnablePut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorEnablePut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.identityTwoFactorEnablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.identityTwoFactorGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TwoFactorApi - factory interface
 * @export
 */
export const TwoFactorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwoFactorApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorDisablePut(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityTwoFactorDisablePut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorEnablePut(options?: AxiosRequestConfig): AxiosPromise<AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse> {
            return localVarFp.identityTwoFactorEnablePut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorGet(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.identityTwoFactorGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwoFactorApi - object-oriented interface
 * @export
 * @class TwoFactorApi
 * @extends {BaseAPI}
 */
export class TwoFactorApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public identityTwoFactorDisablePut(options?: AxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).identityTwoFactorDisablePut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public identityTwoFactorEnablePut(options?: AxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).identityTwoFactorEnablePut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public identityTwoFactorGet(options?: AxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).identityTwoFactorGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TwoFactorRecoveryApi - axios parameter creator
 * @export
 */
export const TwoFactorRecoveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorRecoveryDisablePut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactorRecovery/Disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorRecoverySignInPut: async (identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactorRecovery/SignIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosRecoveryTwoFactorDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwoFactorRecoveryApi - functional programming interface
 * @export
 */
export const TwoFactorRecoveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwoFactorRecoveryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorRecoveryDisablePut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorRecoveryDisablePut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorRecoveryApi.identityTwoFactorRecoveryDisablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorRecoveryApi.identityTwoFactorRecoverySignInPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TwoFactorRecoveryApi - factory interface
 * @export
 */
export const TwoFactorRecoveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwoFactorRecoveryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorRecoveryDisablePut(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityTwoFactorRecoveryDisablePut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwoFactorRecoveryApi - object-oriented interface
 * @export
 * @class TwoFactorRecoveryApi
 * @extends {BaseAPI}
 */
export class TwoFactorRecoveryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorRecoveryApi
     */
    public identityTwoFactorRecoveryDisablePut(options?: AxiosRequestConfig) {
        return TwoFactorRecoveryApiFp(this.configuration).identityTwoFactorRecoveryDisablePut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorRecoveryApi
     */
    public identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options?: AxiosRequestConfig) {
        return TwoFactorRecoveryApiFp(this.configuration).identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserInformationApi - axios parameter creator
 * @export
 */
export const UserInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/UserInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationPut: async (identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/UserInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosUpdateMyUserInformationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInformationApi - functional programming interface
 * @export
 */
export const UserInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityUserInformationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityUserInformationGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserInformationApi.identityUserInformationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserInformationApi.identityUserInformationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserInformationApi - factory interface
 * @export
 */
export const UserInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationGet(options?: AxiosRequestConfig): AxiosPromise<AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse> {
            return localVarFp.identityUserInformationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInformationApi - object-oriented interface
 * @export
 * @class UserInformationApi
 * @extends {BaseAPI}
 */
export class UserInformationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInformationApi
     */
    public identityUserInformationGet(options?: AxiosRequestConfig) {
        return UserInformationApiFp(this.configuration).identityUserInformationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInformationApi
     */
    public identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options?: AxiosRequestConfig) {
        return UserInformationApiFp(this.configuration).identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserPrivilegeApi - axios parameter creator
 * @export
 */
export const UserPrivilegeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserPrivilegeUserIdGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('identityUserPrivilegeUserIdGet', 'userId', userId)
            const localVarPath = `/Identity/UserPrivilege/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {Array<WorkspacesCommonDtosMembersEditUserPrivilegeDTO>} [workspacesCommonDtosMembersEditUserPrivilegeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserPrivilegeUserIdPut: async (userId: string, workspacesCommonDtosMembersEditUserPrivilegeDTO?: Array<WorkspacesCommonDtosMembersEditUserPrivilegeDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('identityUserPrivilegeUserIdPut', 'userId', userId)
            const localVarPath = `/Identity/UserPrivilege/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspacesCommonDtosMembersEditUserPrivilegeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPrivilegeApi - functional programming interface
 * @export
 */
export const UserPrivilegeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPrivilegeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityUserPrivilegeUserIdGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkspacesCommonDtosMembersUserPrivilegesRowDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityUserPrivilegeUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPrivilegeApi.identityUserPrivilegeUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {Array<WorkspacesCommonDtosMembersEditUserPrivilegeDTO>} [workspacesCommonDtosMembersEditUserPrivilegeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityUserPrivilegeUserIdPut(userId: string, workspacesCommonDtosMembersEditUserPrivilegeDTO?: Array<WorkspacesCommonDtosMembersEditUserPrivilegeDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityUserPrivilegeUserIdPut(userId, workspacesCommonDtosMembersEditUserPrivilegeDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPrivilegeApi.identityUserPrivilegeUserIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPrivilegeApi - factory interface
 * @export
 */
export const UserPrivilegeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPrivilegeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserPrivilegeUserIdGet(userId: string, options?: AxiosRequestConfig): AxiosPromise<Array<WorkspacesCommonDtosMembersUserPrivilegesRowDTO>> {
            return localVarFp.identityUserPrivilegeUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {Array<WorkspacesCommonDtosMembersEditUserPrivilegeDTO>} [workspacesCommonDtosMembersEditUserPrivilegeDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserPrivilegeUserIdPut(userId: string, workspacesCommonDtosMembersEditUserPrivilegeDTO?: Array<WorkspacesCommonDtosMembersEditUserPrivilegeDTO>, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityUserPrivilegeUserIdPut(userId, workspacesCommonDtosMembersEditUserPrivilegeDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPrivilegeApi - object-oriented interface
 * @export
 * @class UserPrivilegeApi
 * @extends {BaseAPI}
 */
export class UserPrivilegeApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPrivilegeApi
     */
    public identityUserPrivilegeUserIdGet(userId: string, options?: AxiosRequestConfig) {
        return UserPrivilegeApiFp(this.configuration).identityUserPrivilegeUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {Array<WorkspacesCommonDtosMembersEditUserPrivilegeDTO>} [workspacesCommonDtosMembersEditUserPrivilegeDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPrivilegeApi
     */
    public identityUserPrivilegeUserIdPut(userId: string, workspacesCommonDtosMembersEditUserPrivilegeDTO?: Array<WorkspacesCommonDtosMembersEditUserPrivilegeDTO>, options?: AxiosRequestConfig) {
        return UserPrivilegeApiFp(this.configuration).identityUserPrivilegeUserIdPut(userId, workspacesCommonDtosMembersEditUserPrivilegeDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WalletTransactionApi - axios parameter creator
 * @export
 */
export const WalletTransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        marketPlaceWalletTransactionPost: async (apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/WalletTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletTransactionApi - functional programming interface
 * @export
 */
export const WalletTransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletTransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async marketPlaceWalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceWalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletTransactionApi.marketPlaceWalletTransactionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WalletTransactionApi - factory interface
 * @export
 */
export const WalletTransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletTransactionApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        marketPlaceWalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundResponseDto> {
            return localVarFp.marketPlaceWalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletTransactionApi - object-oriented interface
 * @export
 * @class WalletTransactionApi
 * @extends {BaseAPI}
 */
export class WalletTransactionApi extends BaseAPI {
    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto} [apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WalletTransactionApi
     */
    public marketPlaceWalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto?: ApiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options?: AxiosRequestConfig) {
        return WalletTransactionApiFp(this.configuration).marketPlaceWalletTransactionPost(apiGiftGatewayCommonDTOsFinancialWalletsWalletTransferFundDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WithdrawApi - axios parameter creator
 * @export
 */
export const WithdrawApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        marketPlaceWithdrawPost: async (apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MarketPlace/Withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ActiveShop required
            await setApiKeyToObject(localVarHeaderParameter, "ActiveShop", configuration)

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WithdrawApi - functional programming interface
 * @export
 */
export const WithdrawApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WithdrawApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async marketPlaceWithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketPlaceWithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WithdrawApi.marketPlaceWithdrawPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WithdrawApi - factory interface
 * @export
 */
export const WithdrawApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WithdrawApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        marketPlaceWithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.marketPlaceWithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WithdrawApi - object-oriented interface
 * @export
 * @class WithdrawApi
 * @extends {BaseAPI}
 */
export class WithdrawApi extends BaseAPI {
    /**
     * 
     * @param {ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO} [apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof WithdrawApi
     */
    public marketPlaceWithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO?: ApiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options?: AxiosRequestConfig) {
        return WithdrawApiFp(this.configuration).marketPlaceWithdrawPost(apiGiftGatewayCommonDTOsFinancialCashoutsAddCashoutDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



