import { GridResult } from "../../components/Grid/Grid.models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { ShopsApiFactory, WorkspacesCommonEnumsShopsShopStatus, ShopApiFactory } from "../../generated-api";
import { LocalCurrencyApiFactory } from "../../generated-api/financials";

export default class ShopService {

    getShops(query: ShopListPagination): Promise<GridResult<ShopRowDto>> {
        return mapToDataOnly<GridResult<ShopRowDto>>(ShopsApiFactory().sellerShopGet(query.status as WorkspacesCommonEnumsShopsShopStatus, query.pageskip - 1, query.pagesize));
    }

    getTitles(requestConfig?: any): Promise<{ title: string, value: string }[]> {
        return mapToDataOnly<{ title: string, value: string }[]>(ShopsApiFactory().marketPlaceShopTitleValueGet(requestConfig));
    }

    getOverview(): Promise<{ policies: string[], isOwner: boolean, isSuspended: boolean }> {
        return mapToDataOnly<any>(ShopsApiFactory().marketPlaceShopOverviewGet());
    }

    getCurrent(): Promise<UpdateShopDTO> {
        return mapToDataOnly<UpdateShopDTO>(ShopApiFactory().marketPlaceShopCurrentGet());
    }

    create(shop: CreateShopDto): Promise<{}> {
        return mapToDataOnly<{}>(ShopsApiFactory().marketPlaceShopPost(shop));
    }

    update(shop: UpdateShopDTO): Promise<{}> {
        return mapToDataOnly<{}>(ShopApiFactory().buyerShopPut(shop));
    }

    getLocalCurrency(): Promise<ShopLocalCurrencyDto> {
        return mapToDataOnly<ShopLocalCurrencyDto>(LocalCurrencyApiFactory().apiV1LocalCurrencyGet());
    }

    getShopInfo(walletId: string): Promise<{ title: string, value: string }> {
        return mapToDataOnly<{ title: string, value: string }>(ShopApiFactory().marketPlaceShopShopInfoWalletIdGet(walletId));
    }
}

export interface ShopListPagination extends PaginationQuery {
    status?: number;
}

interface ShopLocalCurrencyDto {
    currencyKey: string
    currencySymbol: string
    currencyTitle: string
    localCurrencyId: string
}


interface CreateShopDto {
    address?: string
    email: string
    siteAddreess?: string
    tell?: string
    title: string
}


interface ShopRowDto {
    activeAt: string
    createAt: string
    id: string
    memberCount: number
    number: string
    status: number
    title: string
}

export interface UpdateShopDTO {
    title: string
    siteAddreess: string
    email: string
    tell: string
    address: string
    shopId: string
}